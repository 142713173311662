// https://magic.reactjs.net/htmltojsx.htm
import React from 'react'
import {
  Row,
  Col,
  ToastContainer
}
from 'mdbreact'
import './AppWeddingPage.css'
import WeddingData from './../member/WeddingData.jsx'

class AppWeddingPage extends React.Component {
  render() {
    var wedding_id
    var mode
    if (window.location.pathname.split('/')[1] === "wedding") {
      wedding_id = window.location.pathname.split('/')[2]
      mode = "Z"
    } else {
      wedding_id = 0
      mode = "P"
    }
    return (
      <Row id="weddingData" className="container-fluid justify-content-center">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          draggable={false}
          pauseOnHover={false}>
        </ToastContainer>
        <Col className="col-12">
          <Row>
            <Col className="col-12 text-left">
              <Row className="my-5">
                <Col>
                  <h1 className="text-center">Notice of Intended Marriage Information</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <WeddingData mode={mode} id={wedding_id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default AppWeddingPage
