// https://magic.reactjs.net/htmltojsx.htm

import React, { Component } from 'react'
import _ from 'lodash'
import { Spinner, Row, Col, Button, Modal, ModalBody, ToastContainer } from 'mdbreact'
//React Table
import matchSorter from 'match-sorter'
import './Weddings.css'
import WeddingData from './WeddingData.jsx'
import SingleSelect from '../general/SingleSelect'
import axios from "axios"
import ReactTable from "react-table"
import * as Sentry from '@sentry/browser'
import "react-table/react-table.css"

class WeddingsPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			id: 0,
			index: 0,
			modal: false,
			loaded: false,
			mode: "E", //"E"dit wedding or "N"ew wedding
			status: "Initial",
			manager: "N",
			sortData: {},
			filter: "I", //'I'nitial, 'A'll, 'H'ide archives, 'S'how only archives
			allWeddings: [],
			weddings: [],
			names: []
		}
		this.open = this.open.bind(this)
		this.close = this.close.bind(this)
		this.newWedding = this.newWedding.bind(this)
		this.initialSetup = this.initialSetup.bind(this)
		this.getStatus = this.getStatus.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
	}
	componentWillMount() {
		this.getStatus()
		this.initialSetup()
	}
	handleFilterChange(name, value) {
		this.setState({
			filter: value.value,
			loaded: false
		}, () => this.initialSetup())
	}
	getStatus() {
		var _this = this
		axios.get("/member/api/users/get-status")
			.then(function (response) {
				_this.setState({
					status: response.data[0].status,
					manager: response.data[0].manager
				})
			})
			.catch(function (error) {
				Sentry.captureException(error)
				console.log(error)
				window.location.replace("/");
			})
	}
	initialSetup() {
		var _this = this
		axios.get("/member/api/weddings/get-weddings/" + this.state.filter)
			.then(function (response) {
				var dataCopy1 = JSON.parse(JSON.stringify(response.data.weddings))
				var dataCopy2 = JSON.parse(JSON.stringify(response.data.weddings))
				// var filter = _this.state.filter
				var sortData = response.data.sortData
				if (sortData && sortData.length > 0) {
					for (var z = 0; z < sortData.length; z++) {
						sortData[z].id = sortData[z].name
						delete sortData[z].name
					}
				}
				axios.get('/member/api/users/get-names')
					.then(function (nameResponse) {
						_this.setState({
							sortData: sortData,
							filter: response.data.filter,
							weddings: dataCopy1,
							allWeddings: dataCopy2,
							loaded: true,
							names: nameResponse.data
						})
					})
			})
			.catch(function (error) {
				Sentry.captureException(error)
				console.log(error)
				// window.location.replace("/")
			})
	}
	open(rowInfo) {
		var _this = this
		const weddingToView = this.state.weddings.findIndex(wedding => wedding.id === rowInfo.id)
		this.setState({
			id: _this.state.weddings[weddingToView].id,
			index: weddingToView,
			modal: true,
			mode: "E"
		})
	}
	newWedding() {
		if (this.state.status !== "I") {
			this.setState({
				id: 0,
				modal: true,
				mode: "N"
			})
		}
	}
	close() {
		var _this = this
		this.setState({
			modal: false,
			// loaded: false
		}, () => {
			setTimeout(function () {
				_this.setState({
					id: 0,
					index: 0
				})
			}, 100)
			// Bootstrap 4 modals have a default transition time of 300
		})
		_this.initialSetup()
	}
	render() {
		var _this = this
		var times = []
		times.push(<option key="0" value=""></option>)
		_.map(_.range(1, 13), function (value) {
			times.push(<option key={value} value={value + ".00"}>{value + ".00"}</option>)
			times.push(<option key={value + 0.15} value={value + ".15"}>{value + ".15"}</option>)
			times.push(<option key={value + 0.30} value={value + ".30"}>{value + ".30"}</option>)
			times.push(<option key={value + 0.45} value={value + ".45"}>{value + ".45"}</option>)
		})
		return (
			<div className="col-xs-12 text-center" style={{ paddingTop: "30px", fontSize: "22px" }}>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					closeButton={false}
					hideProgressBar
					newestOnTop={false}
					rtl={false}
					draggable={false}
					pauseOnHover={false}>
				</ToastContainer>
				<h1>
					Weddings
        </h1>
				{
					this.state.status === "I" ?
						<div id="inactiveAlert" className="alert alert-danger text-center mb-2 pb-0 hide" role="alert">
							<p>Your account is 'Inactive'</p>
							<p></p>
							<p>You are no longer able to create or edit weddings.</p>
							<p></p>
							<p>Please obtain a licence by clicking on "User Settings" above, and making a payment.</p>
						</div> :
						<div></div>
				}
				{this.state.loaded ?
					<Row>
						<Col className="text-center">
							<Button
								id="newWeddingButton"
								onClick={this.newWedding}
								className="mt-3 mb-4 btn-lg"
								color="primary">Create Wedding</Button>
						</Col>
					</Row>
					:
					<div></div>
				}
				{this.state.loaded ?
					<Row className="mb-3">
						<Col></Col>
						<Col className="col-12 col-md-4">
							<label>Hide Archived?</label>
							<SingleSelect
								defaultValue={this.state.filter}
								onChange={this.handleFilterChange}
								name="archived"
								options={[
									{
										value: "A",
										label: "Show All Weddings"
									},
									{
										value: "H",
										label: "Hide Archived Weddings"
									},
									{
										value: "S",
										label: "Show ONLY Archived Weddings"
									}
								]} />
						</Col>
						<Col></Col>
					</Row>
					:
					<div></div>
				}
				{this.state.loaded ?
					<div id="weddingsTable" className="text-left">
						<ReactTable
							data={this.state.weddings}
							filterable
							className="-striped -highlight"
							getTdProps={(state, rowInfo, column, instance) => {
								return {
									onClick: (e, handleOriginal) => {
										if (rowInfo && rowInfo.row) {
											_this.open(rowInfo.row)
										}
									}
								}
							}}
							getTrProps={(state, rowInfo, column) => {
								var returnData = {
									backgroundColor: ""
								}
								if (rowInfo && rowInfo.row && rowInfo.row.id === _this.state.id) {
									returnData = {
										// background: "#dc3545"
										backgroundColor: "#0b51c5"
									}
								}
								return {
									style: returnData
								}
							}}
							columns={[
								{
									columns: [
										{
											Header: "ID",
											id: "id",
											accessor: "id",
											show: (_this.state.manager === "E" ? true : false),
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ["id"] }),
											filterAll: true
										},
										{
											Header: "Party Names",
											id: "party12",
											accessor: "party12",
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ["party12"] }),
											filterAll: true
										},
										{
											Header: "Date",
											id: "marriagedate_date",
											accessor: "marriagedate_date",
											// accessor: d => d.marriagedate_date,
											// accessor: (row) => {
											//   if (row.marriagedate_date === 0) {
											//     return "Unknown Marriage Date"
											//   } else {
											//     return moment.unix(row.marriagedate_date).format("Do MMMM YYYY");
											//   }
											// },
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ["marriagedate_date"] }),
											filterAll: true,
											sortMethod: (a, b) => {
												if (new Date(a) > new Date(b)) {
													return -1
												} else {
													return 1
												}
											},
											// Cell: row => {
											//   if (row.value === "ERROR!" || row.value === "Loading...") {
											//     return <div>{row.value}</div>
											//   } else {
											//     return <div>{(row.value < 1 || row.value === "ERROR!" || row.value === "Loading..." ? "" : moment.unix(row.value).format("Do MMMM YYYY"))}</div>
											//   }
											// }
										},
										{
											Header: "CMC",
											id: "form15",
											accessor: "form15",
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ["form15"] }),
											filterAll: true
										},
										{
											Header: "Updated",
											id: "updated_at",
											accessor: "updated_at",
											// accessor: d => d.updated_at,
											// accessor: (row) => {
											//   if (row.updated_at === 0) {
											//     return "Unknown Marriage Date"
											//   } else {
											//     return moment.unix(row.updated_at).format("Do MMMM YYYY");
											//   }
											// },
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ["updated_at"] }),
											filterAll: true,
											sortMethod: (a, b) => {
												if (new Date(a) > new Date(b)) {
													return -1
												} else {
													return 1
												}
											},
											// 	Cell: row => {
											// 	    console.log(new Date(row.value))
											//       return <div>{moment(row.value).format("YYYY-MM-DD")}</div>
											// }
										},
									]
								}
							]}
							showPageSizeOptions={true}
							pageSizeOptions={[5, 10, 20, 25, 50, 100]}
							defaultPageSize={10}
							onSortedChange={sorted => {
								this.setState({
									sortData: sorted
								})
								axios.post("/member/api/set-sort", { sortData: sorted })
									.then(function (response) {
										//nothing to do
									})
									.catch(function (error) {
										Sentry.captureException(error)
										console.log(error)
									})
							}}
							sorted={this.state.sortData}
						/>
					</div>
					:
					<Col className="col-12">
						<Row>
							<Col className="col-12 text-center">
								<Spinner blue big />
							</Col>
						</Row>
					</Col>
				}
				<Modal style={{ minWidth: "95vw", maxWidth: "95vw" }} size="fluid" isOpen={this.state.modal} toggle={this.close} keyboard={false} backdrop="static" position="top" >
					<ModalBody >
						<WeddingData id={this.state.id} close={this.close} mode={this.state.mode} status={this.state.status} names={this.state.names} />
					</ModalBody>
				</Modal>

			</div>
		)
	}
}

export default WeddingsPage
