import React, { Component } from "react"
import Select from "react-select"

const colourStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white' }),
	input: styles => ({ ...styles, marginTop: 10 }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return { ...styles, backgroundColor: (isSelected ? '#4285f4' : 'white') }
	}
}

class SingleSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {
			defaultObjectIndex: 0
		}
	}
	componentWillMount() { }
	render() {
		var defaultObjectIndex = this.props.options.findIndex(item => item.value === this.props.defaultValue)
		if (defaultObjectIndex === -1) {
			defaultObjectIndex = 0
		}
		return (
			<Select
				defaultValue={this.props.options[defaultObjectIndex]}
				value={this.props.options[defaultObjectIndex]}
				name={this.props.name}
				id={this.props.name}
				options={this.props.options}
				onChange={this.props.onChange.bind(this, this.props.name)}
				styles={colourStyles}
				className="basic-single text-left"
				classNamePrefix="select"
				isDisabled={this.props.disabled}
				isLoading={false}
				isClearable={false}
				isRtl={false}
				isSearchable={true}
			/>
		)
	}
}

export default SingleSelect
