// https://magic.reactjs.net/htmltojsx.htm
import React from "react";
import moment from "moment";
// import { Col, Input, ModalFooter, Button, Label, FormGroup, Card, CardTitle, Row, Container, Form, Modal, ModalHeader, ModalBody } from 'mdbreact'
import { BrowserRouter as Router } from "react-router-dom";
import {
	Spinner,
	Button,
	CardHeader,
	Badge,
	Card,
	CardBody,
	TabPane,
	TabContent,
	Nav,
	NavItem,
	NavLink,
	Fa,
	Row,
	Col,
	toast,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormInline,
	Input,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBTooltip,
} from "mdbreact";
import classnames from "classnames";
import "./WeddingData.css";
import SingleSelect from "../general/SingleSelect";
import _ from "lodash";
import axios from "axios";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/browser";

import "react-quill/dist/quill.snow.css";

function createMarkup(data) {
	return { __html: data };
}

var lastMarriageEndOptions = [
	{
		value: "",
		label: "Select...",
	},
	{
		value: "Death",
		label: "Death",
	},
	{
		value: "Nullity",
		label: "Nullity",
	},
	{
		value: "Divorce",
		label: "Divorce",
	},
];

var conjugalOptions = [
	{
		value: "Never Validly Married",
		label: "Never Validly Married",
	},
	{
		value: "Divorced",
		label: "Divorced",
	},
	{
		value: "Widow",
		label: "Widow",
	},
	{
		value: "Widower",
		label: "Widower",
	},
	{
		value: "Married - Divorce Pending",
		label: "Married - Divorce Pending",
	},
];

var yesNoOptions = [
	{
		value: false,
		label: "No",
	},
	{
		value: true,
		label: "Yes",
	},
];

var australianStates = [
	{
		value: "",
		label: "-",
	},
	{
		value: "Australian Capital Territory",
		label: "Australian Capital Territory",
	},
	{
		value: "New South Wales",
		label: "New South Wales",
	},
	{
		value: "Northern Territory",
		label: "Northern Territory",
	},
	{
		value: "Queensland",
		label: "Queensland",
	},
	{
		value: "South Australia",
		label: "South Australia",
	},
	{
		value: "Tasmania",
		label: "Tasmania",
	},
	{
		value: "Victoria",
		label: "Victoria",
	},
	{
		value: "Western Australia",
		label: "Western Australia",
	},
];
var dateDays = [];
dateDays.push({
	value: "",
	label: "Day",
});
_.map(_.range(1, 32), function (value) {
	dateDays.push({
		value: value.toString(),
		label: value.toString(),
	});
});

var numberOfMonths = [];
numberOfMonths.push({
	value: "0",
	label: "0",
});
_.map(_.range(1, 12), function (value) {
	numberOfMonths.push({
		value: value.toString(),
		label: value.toString(),
	});
});

var marriageCount = [];
marriageCount.push({
	value: "0",
	label: "0",
});
_.map(_.range(1, 11), function (value) {
	marriageCount.push({
		value: value.toString(),
		label: value.toString(),
	});
});
var dateMonths = [];
dateMonths.push({
	value: "",
	label: "Month",
});
_.map(_.range(1, 13), function (value) {
	var monthName = moment("01/" + value + "/2018", "DD/M/YYYY").format("MMMM");
	dateMonths.push({
		label: `${value} - ${monthName}`,
		value: monthName,
	});
});
var dateYears = [];
dateYears.push({
	value: "",
	label: "Year",
});
_.map(_.range(2016, 2031), function (value) {
	dateYears.push({
		value: value.toString(),
		label: value.toString(),
	});
});
var dateDOBYears = [];
dateDOBYears.push({
	value: "",
	label: "Year",
});
_.map(_.range(2030, 1900), function (value) {
	dateDOBYears.push({
		value: value.toString(),
		label: value.toString(),
	});
});
var times = [];
times.push({
	value: "",
	label: "Select...",
});
_.map(_.range(1, 13), function (value) {
	times.push({
		value: value + ".00",
		label: value + ".00",
	});
	times.push({
		value: value + ".15",
		label: value + ".15",
	});
	times.push({
		value: value + ".30",
		label: value + ".30",
	});
	times.push({
		value: value + ".45",
		label: value + ".45",
	});
});

var newWeddingData = {
	id: 0,
	groom_surname: "",
	groom_firstandother: "",
	groom_occupation: "",
	groom_address: "",
	groom_address2: "",
	groom_city: "",
	groom_state: "",
	groom_birthplace: "",
	groom_history: "Never Validly Married",
	groom_dobd: "",
	groom_lastd: "",
	groom_bornoutaus: false,
	groom_yearsresidancy: "",
	groom_monthsresidancy: "",
	groom_fathersname: "",
	groom_father_birth_name: "",
	groom_fatherdeceased: false,
	groom_mothersname: "",
	groom_mother_birth_name: "",
	groom_motherdeceased: false,
	groom_fathercob: "",
	groom_mothercob: "",
	groom_previousmarriages: "0",
	groom_yearspreviousmarriages: "",
	groom_numberchildprevmarriagebornalive: "",
	groom_yearbirtheachchild: "",
	groom_howlastmarriageend: "Divorce",
	groom_regnum: "",
	groom_regnum_produced: false,
	groom_licence: false,
	groom_licencenum: "",
	groom_proofage: false,
	groom_proofagenum: "",
	groom_currentiden: false,
	groom_currentidentype: "",
	groom_currentidennum: "",
	groom_overseaspass: "",
	groom_pass_produced: false,
	groom_statdec: false,
	groom_stat_dec_num: "",
	groom_doc: true,
	groom_doc_state: "",
	groom_evidence: false,
	groom_death_cert_num: "",
	groom_evidencen: false,
	groom_evidences: false,
	bride_surname: "",
	bride_firstandother: "",
	bride_occupation: "",
	bride_address: "",
	bride_address2: "",
	bride_city: "",
	bride_state: "",
	bride_birthplace: "",
	bride_history: "Never Validly Married",
	bride_dobd: "",
	bride_lastd: "",
	bride_bornoutaus: false,
	bride_yearsresidancy: "",
	bride_monthsresidancy: "",
	bride_fathersname: "",
	bride_father_birth_name: "",
	bride_fatherdeceased: false,
	bride_mothersname: "",
	bride_mother_birth_name: "",
	bride_motherdeceased: false,
	bride_fathercob: "",
	bride_mothercob: "",
	bride_previousmarriages: "0",
	bride_yearspreviousmarriages: "",
	bride_numberchildprevmarriagebornalive: "",
	bride_yearbirtheachchild: "",
	bride_howlastmarriageend: "Divorce",
	bride_regnum: "",
	bride_regnum_produced: false,
	bride_licence: false,
	bride_licencenum: "",
	bride_proofage: false,
	bride_proofagenum: "",
	bride_currentiden: false,
	bride_currentidentype: "",
	bride_currentidennum: "",
	bride_overseaspass: "",
	bride_pass_produced: false,
	bride_statdec: false,
	bride_stat_dec_num: "",
	bride_doc: true,
	bride_doc_state: "",
	bride_evidence: false,
	bride_death_cert_num: "",
	bride_evidencen: false,
	bride_evidences: false,
	partiesrelated: false,
	relationship: "",
	datenoticereceivedbycelebrant: "",
	ritesused: "Marriage Act 1961",
	datemarriagesol: "",
	marriagetime: "",
	marriagetimeampm: false,
	marriageampm: "",
	marriagedate: "",
	// marriageat1: "Maple & Rose House, Perth",
	marriageat1: "Old Tower House, 115 Francis Street, Perth",
	marriageat2: "",
	marriagestate: "",
	marriagewitness1: "",
	marriagewitness2: "",
	groom_decplace: "Perth",
	groom_decdate: "",
	bride_decplace: "Perth",
	bride_decdate: "",
	placemarriagesol: "",
	placemarriagesol2: "",
	court1: "",
	court2: "",
	groom_consent: false,
	bride_consent: false,
	groom_courtapp: false,
	bride_courtapp: false,
	approvalshort: false,
	form15: "",
	paymenttotal: "0",
	paymentreceived: "0",
	groom_under18: false,
	groom_courtname: "",
	groom_courtlocation: "",
	groom_courtdate: "",
	bride_under18: false,
	bride_courtname: "",
	bride_courtlocation: "",
	bride_courtdate: "",
	statuscomplete: "",
	statuspaid: "",
	statusupcoming: "",
	creation_date: new Date(),
	noimfontsize: 0.0,
	formfontsize: 0.0,
	user_id: 0,
	created_at: new Date(),
	updated_at: new Date(),
	groom_state_selection: "",
	bride_state_selection: "",
	marriagestate_selection: "Western Australia",
	marriagesol2state_selection: "",
	groom_noim_signature_date: new Date(),
	bride_noim_signature_date: new Date(),
	groom_dobd_date: new Date(),
	groom_lastd_date: new Date(),
	bride_dobd_date: new Date(),
	bride_lastd_date: new Date(),
	datenoticereceivedbycelebrant_date: 0,
	datemarriagesol_date: new Date(),
	marriagedate_date: new Date(),
	groom_decdate_date: new Date(),
	bride_decdate_date: new Date(),
	groom_courtdate_date: new Date(),
	bride_courtdate_date: new Date(),
	groom_birthplace_state: "",
	bride_birthplace_state: "",
	groom_birthplace_state_custom: "",
	bride_birthplace_state_custom: "",
	groom_noim_signature_day: "",
	groom_noim_signature_month: "",
	groom_noim_signature_year: moment().format("YYYY"),
	bride_noim_signature_day: "",
	bride_noim_signature_month: "",
	bride_noim_signature_year: moment().format("YYYY"),
	groom_dobd_day: "",
	groom_dobd_month: "",
	groom_dobd_year: "",
	groom_lastd_day: "",
	groom_lastd_month: "",
	groom_lastd_year: "",
	bride_dobd_day: "",
	bride_dobd_month: "",
	bride_dobd_year: "",
	bride_lastd_day: "",
	bride_lastd_month: "",
	bride_lastd_year: "",
	datenoticereceivedbycelebrant_day: "",
	datenoticereceivedbycelebrant_month: "",
	datenoticereceivedbycelebrant_year: "",
	datemarriagesol_day: "",
	datemarriagesol_month: "",
	datemarriagesol_year: moment().format("YYYY"),
	marriagedate_day: "",
	marriagedate_month: "",
	marriagedate_year: moment().format("YYYY"),
	groom_decdate_day: "",
	groom_decdate_month: "",
	groom_decdate_year: moment().format("YYYY"),
	bride_decdate_day: "",
	bride_decdate_month: "",
	bride_decdate_year: moment().format("YYYY"),
	groom_courtdate_day: "",
	groom_courtdate_month: "",
	groom_courtdate_year: moment().format("YYYY"),
	bride_courtdate_day: "",
	bride_courtdate_month: "",
	bride_courtdate_year: moment().format("YYYY"),
	summary_count: 0,
	wedding_info_count: 0,
	groom_bride_count: 0,
	extra_noim_count: 0,
	under18_count: 0,
	payments_count: 0,
	total_field_count: 0,
	archivewedding: false,
	notes: "",
	groom_desc: "G",
	bride_desc: "B",
	groom_sex: "M",
	bride_sex: "F",
	locked: "locked",
	public_link: "",
	groom_email: "",
	bride_email: "",
	groom_number: "",
	bride_number: "",
	// form_address: "Maple & Rose House, Perth"
	form_address: "Old Tower House, Perth",
	planned_marriage_date_day: "",
	planned_marriage_date_month: "",
	planned_marriage_date_year: "",
	planned_marriage_other: "",
	ceremony_used: "civil ceremony according to the Marriage Act 1961",
	groom_auth_witness: "",
	bride_auth_witness: "",
	witness_1_no_form: "",
	witness_2_no_form: ""
};

var store = (window.store = observable({
	data: { newWeddingData },
	files: [],
	create_wedding: false,
	mode: "", //'P'ublic, 'N'ew (member), 'E'dit (member), 'Z'(Public Link)
	id: 0,
	activeItem: "2",
	originalData: {},
	loaded: false,
	dirty: false,
	publicDirty: false,
	saveButtonText: "",
	modal: false,
	deleteModal: false,
	disableMarriageState: false,
	disableMarriageSOL2State: false,
	disableGroomPreviousMarriages: false,
	disableBridePreviousMarriages: false,
	disableGroomUnder18: false,
	disableBrideUnder18: false,
	disableGroomState: false,
	disableBrideState: false,
	disableGroomBirthState: false,
	disableBrideBirthState: false,
	disableGroomBornOut: false,
	disableBrideBornOut: false,
	marriagedate_weekday: "Invalid Date",
	deleteFilename: "",
	locked: false,
	topMessage: "",
	bottomMessage: "",
	finalModal: false,
	currentUser: 1,
	names: [
		{
			id: 0,
			first_name: "",
			last_name: "",
		},
		{
			id: 1,
			first_name: "",
			last_name: "",
		},
	],
}));

store.fileUpload = function (event) {
	var formData = new FormData();
	formData.append("file", event.target.files[0]);
	var fileName = event.target.files[0].name.toLowerCase();
	event.target.value = null;
	var nameCheck = false;
	store.files.forEach(function (element) {
		if (element.filename === fileName) {
			nameCheck = true;
		}
	});
	if (nameCheck === true) {
		toast.error("This file already exists", {
			position: "top-right",
			autoClose: 3000,
			closeButton: false,
			hideProgressBar: false,
			draggable: false,
			pauseOnHover: false,
		});
	} else {
		axios
			.post("/member/api/upload-file/" + store.data.id, formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then(function (response) {
				if (response.status === 200) {
					store.files = response.data;
				} else {
					console.log(response);
				}
			})
			.catch(function (error) {
				Sentry.captureException(error);
				console.log(error);
			});
	}
};
store.checkDisabled = function () {
	var disableMarriageState;
	var disableMarriageSOL2State;
	var disableGroomPreviousMarriages;
	var disableBridePreviousMarriages;
	var disableGroomUnder18;
	var disableBrideUnder18;
	var disableGroomBornOut;
	var disableBrideBornOut;
	var disableGroomState;
	var disableBrideState;
	var disableGroomBirthState;
	var disableBrideBirthState;
	// if (store.data.marriagestate_selection === "Custom") {
	disableMarriageState = false;
	// }
	// else {
	//   disableMarriageState = true
	// }
	// if (store.data.marriagesol2state_selection === "Custom") {
	disableMarriageSOL2State = false;
	// }
	// else {
	//   disableMarriageSOL2State = true
	// }
	if (store.data.groom_previousmarriages === "0") {
		disableGroomPreviousMarriages = true;
	} else {
		disableGroomPreviousMarriages = false;
	}
	if (store.data.bride_previousmarriages === "0") {
		disableBridePreviousMarriages = true;
	} else {
		disableBridePreviousMarriages = false;
	}
	if (store.data.groom_under18 === false) {
		disableGroomUnder18 = true;
	} else {
		disableGroomUnder18 = false;
	}
	if (store.data.bride_under18 === false) {
		disableBrideUnder18 = true;
	} else {
		disableBrideUnder18 = false;
	}
	if (store.data.groom_bornoutaus === false) {
		disableGroomBornOut = true;
	} else {
		disableGroomBornOut = false;
	}
	if (store.data.bride_bornoutaus === false) {
		disableBrideBornOut = true;
	} else {
		disableBrideBornOut = false;
	}
	// if (store.data.groom_state_selection === "Custom") {
	disableGroomState = false;
	// }
	// else {
	//   disableGroomState = true
	// }
	// if (store.data.bride_state_selection === "Custom") {
	disableBrideState = false;
	// }
	// else {
	//   disableBrideState = true
	// }
	// if (store.data.groom_birthplace_state === "Custom") {
	disableGroomBirthState = false;
	// }
	// else {
	//   disableGroomBirthState = true
	// }
	// if (store.data.bride_birthplace_state === "Custom") {
	disableBrideBirthState = false;
	// }
	// else {
	//   disableBrideBirthState = true
	// }
	if (store.data.groom_birthplace_state_custom.length !== 0) {
		store.data.groom_birthplace_state = "-";
	}
	if (store.data.bride_birthplace_state_custom.length !== 0) {
		store.data.bride_birthplace_state = "-";
	}
	if (store.data.groom_state.length !== 0) {
		store.data.groom_state_selection = "-";
	}
	if (store.data.bride_state.length !== 0) {
		store.data.bride_state_selection = "-";
	}
	store.disableMarriageState = disableMarriageState;
	store.disableMarriageSOL2State = disableMarriageSOL2State;
	store.disableGroomPreviousMarriages = disableGroomPreviousMarriages;
	store.disableBridePreviousMarriages = disableBridePreviousMarriages;
	store.disableGroomUnder18 = disableGroomUnder18;
	store.disableBrideUnder18 = disableBrideUnder18;
	store.disableGroomBornOut = disableGroomBornOut;
	store.disableBrideBornOut = disableBrideBornOut;
	store.disableGroomState = disableGroomState;
	store.disableBrideState = disableBrideState;
	store.disableGroomBirthState = disableGroomBirthState;
	store.disableBrideBirthState = disableBrideBirthState;
};
store.componentDidMount = function () {
	if (store.mode === "P" || store.mode === "Z") {
		axios
			.get("/public/api/get-messages")
			.then(function (response) {
				store.topMessage = response.data[0].topMessage;
				store.bottomMessage = response.data[0].bottomMessage;
			})
			.catch(function (error) {
				Sentry.captureException(error);
				console.log(error);
			});
	}
	if (store.mode === "E" || store.mode === "Z") {
		var type;
		if (store.mode === "Z") {
			type = "public";
		} else {
			type = "member";
		}
		axios
			.get("/" + type + "/api/weddings/get-wedding/" + store.id)
			.then(function (response) {
				if (response.data === "LOCKED") {
					store.locked = true;
				} else {
					var dataCopy1 = JSON.parse(JSON.stringify(response.data[0]));
					var dataCopy2 = JSON.parse(JSON.stringify(response.data[0]));
					var newWeekday = "Invalid Date";
					if (
						dataCopy1.marriagedate_day.length > 0 &&
						dataCopy1.marriagedate_month.length > 0 &&
						dataCopy1.marriagedate_year.length > 0
					) {
						newWeekday = moment(
							dataCopy1.marriagedate_day +
							dataCopy1.marriagedate_month +
							dataCopy1.marriagedate_year,
							"DD/MMMM/YYYY"
						).format("dddd");
					} else {
						newWeekday = "Invalid Date";
					}
					store.files = dataCopy1.files;
					store.names = dataCopy1.names;
					delete dataCopy1.files;
					delete dataCopy2.files;
					delete dataCopy1.names;
					delete dataCopy2.names;
					//
					dataCopy1.noimLink = fixLink(dataCopy1.noimLink);
					dataCopy2.noimLink = fixLink(dataCopy2.noimLink);
					dataCopy1.decCertLink = fixLink(dataCopy1.decCertLink);
					dataCopy2.decCertLink = fixLink(dataCopy2.decCertLink);
					dataCopy1.form15Link = fixLink(dataCopy1.form15Link);
					dataCopy2.form15Link = fixLink(dataCopy2.form15Link);
					dataCopy1.confLodge = fixLink(dataCopy1.confLodge);
					dataCopy2.confLodge = fixLink(dataCopy2.confLodge);
					dataCopy1.vows = fixLink(dataCopy1.vows);
					dataCopy2.vows = fixLink(dataCopy2.vows);
					dataCopy1.orderOfCeremony = fixLink(dataCopy1.orderOfCeremony);
					dataCopy2.orderOfCeremony = fixLink(dataCopy2.orderOfCeremony);
					//
					store.data = dataCopy1;
					store.originalData = dataCopy2;
					store.loaded = true;
					store.dirty = false;
					store.publicDirty = false;
					if (store.mode === "E") {
						store.saveButtonText = "SAVE";
					} else if (store.mode === "Z") {
						store.saveButtonText = "Confirm NOIM Information";
					}
					store.marriagedate_weekday = newWeekday;
					store.checkDisabled();
				}
			})
			.catch(function (error) {
				Sentry.captureException(error);
				console.log(error);
				// window.location.replace("/")
			});
	} else if (store.mode === "N" || store.mode === "P") {
		var dataCopy1 = JSON.parse(JSON.stringify(newWeddingData));
		var dataCopy2 = JSON.parse(JSON.stringify(newWeddingData));
		store.data = dataCopy1;

		store.originalData = dataCopy2;
		store.loaded = true;
		if (store.mode === "N") {
			store.saveButtonText = "CREATE";
		} else {
			store.saveButtonText = "Confirm NOIM Information";
		}

		store.checkDisabled();
	}
};
store.copyAddressFromParty1 = function () {
	store.data.bride_address = store.data.groom_address;
	store.data.bride_address2 = store.data.groom_address2;
	store.data.bride_city = store.data.groom_city;
	store.data.bride_state_selection = store.data.groom_state_selection;
	store.data.bride_state = store.data.groom_state;
	store.checkDisabled();
	store.checkDataForChanges();
};
store.copyAddressFromParty2 = function () {
	store.data.groom_address = store.data.bride_address;
	store.data.groom_address2 = store.data.bride_address2;
	store.data.groom_city = store.data.bride_city;
	store.data.groom_state_selection = store.data.bride_state_selection;
	store.data.groom_state = store.data.bride_state;
	store.checkDisabled();
	store.checkDataForChanges();
	// this.setState({
	//   data: dataCopy
	// }, () => {
	//   this.checkDisabled()
	//   this.checkDataForChanges()
	// })
};
store.toggleModal = function () {
	// this.setState({
	store.modal = !store.modal;
	// });
};
store.toggleFinalModal = function () {
	// this.setState({
	store.finalModal = !store.finalModal;
	// });
};
store.toggleDeleteModal = function () {
	store.deleteModal = !store.deleteModal;
};
store.deleteFileConfirmed = function () {
	axios
		.post(
			"/member/api/delete-file/" + store.data.id + "/" + store.deleteFilename
		)
		.then(function (response) {
			var index;
			for (var z = 0; z < store.files.length; z++) {
				if (
					store.files[z].filename.toLowerCase() ===
					store.deleteFilename.toLowerCase()
				) {
					index = z;
				}
			}
			store.files.splice(index, 1);
		})
		.catch(function (error) {
			Sentry.captureException(error);
			console.log(error);
		});
};
store.checkDataForChanges = function () {
	var dirty = JSON.stringify(store.data) !== JSON.stringify(store.originalData);
	// if (dirty !== store.dirty) {
	store.dirty = dirty;
	store.publicDirty = dirty;
	// store.create_wedding = false
	// }
	if (
		store.data.groom_firstandother.length === 0 ||
		store.data.groom_dobd_day.length === 0 ||
		store.data.groom_dobd_month.length === 0 ||
		store.data.groom_dobd_year.length === 0 ||
		store.data.groom_birthplace.length === 0 ||
		store.data.groom_birthplace_state.length === 0 ||
		store.data.groom_email.length === 0 ||
		store.data.groom_number.length === 0
	) {
		store.publicDirty = false;
	}
	if (
		store.data.bride_firstandother.length === 0 ||
		store.data.bride_dobd_day.length === 0 ||
		store.data.bride_dobd_month.length === 0 ||
		store.data.bride_dobd_year.length === 0 ||
		store.data.bride_birthplace.length === 0 ||
		store.data.bride_birthplace_state.length === 0 ||
		store.data.bride_email.length === 0 ||
		store.data.bride_number.length === 0
	) {
		store.publicDirty = false;
	}
};

store.handleSelectChangeNames = function (name, value) {
	store.currentUser = value.value;
	store.data.noimLink = fixLink(store.data.noimLink);
	store.data.decCertLink = fixLink(store.data.decCertLink);
	store.data.form15Link = fixLink(store.data.form15Link);
	store.data.confLodge = fixLink(store.data.confLodge);
	store.data.vows = fixLink(store.data.vows);
	store.data.orderOfCeremony = fixLink(store.data.orderOfCeremony);
};

store.handleSelectChange = function (name, value) {
	store.data[name] = value.value;
	var newWeekday = store.marriagedate_weekday;
	if (
		name === "marriagedate_day" ||
		name === "marriagedate_month" ||
		name === "marriagedate_year"
	) {
		if (
			store.data.marriagedate_day.length > 0 &&
			store.data.marriagedate_month.length > 0 &&
			store.data.marriagedate_year.length > 0
		) {
			newWeekday = moment(
				store.data.marriagedate_day +
				store.data.marriagedate_month +
				store.data.marriagedate_year,
				"DD/MMMM/YYYY"
			).format("dddd");
		} else {
			newWeekday = "Invalid Date";
		}
		if (
			store.data.datemarriagesol_day === store.data.groom_decdate_day &&
			store.data.datemarriagesol_month === store.data.groom_decdate_month &&
			store.data.datemarriagesol_year === store.data.groom_decdate_year
		) {
			store.data.groom_decdate_day = store.data.marriagedate_day;
			store.data.groom_decdate_month = store.data.marriagedate_month;
			store.data.groom_decdate_year = store.data.marriagedate_year;
		}
		if (
			store.data.datemarriagesol_day === store.data.bride_decdate_day &&
			store.data.datemarriagesol_month === store.data.bride_decdate_month &&
			store.data.datemarriagesol_year === store.data.bride_decdate_year
		) {
			store.data.bride_decdate_day = store.data.marriagedate_day;
			store.data.bride_decdate_month = store.data.marriagedate_month;
			store.data.bride_decdate_year = store.data.marriagedate_year;
		}
		store.data.datemarriagesol_day = store.data.marriagedate_day;
		store.data.datemarriagesol_month = store.data.marriagedate_month;
		store.data.datemarriagesol_year = store.data.marriagedate_year;
	}
	if (name === "marriagestate_selection") {
		store.data.marriagesol2state_selection = store.data.marriagestate_selection;
	}
	if (name === "groom_desc") {
		if (value.value === "G") {
			store.data.groom_sex = "M";
		} else if (value.value === "B") {
			store.data.groom_sex = "F";
		}
	} else if (name === "bride_desc") {
		if (value.value === "G") {
			store.data.bride_sex = "M";
		} else if (value.value === "B") {
			store.data.bride_sex = "F";
		}
	}
	var day, month, year;
	if (
		name === "groom_dobd_day" ||
		name === "groom_dobd_month" ||
		name === "groom_dobd_year"
	) {
		day = store.data.groom_dobd_day;
		month = store.data.groom_dobd_month;
		year = store.data.groom_dobd_year;
		if (name === "groom_dobd_day") {
			day = value.value;
		} else if (name === "groom_dobd_month") {
			month = value.value;
		} else if (name === "groom_dobd_year") {
			year = value.value;
		}
		if (moment(day + month + year, "DDMMMMYYYY").isValid()) {
			if (
				parseInt(year) > 2000 &&
				moment(day + month + year, "DDMMMMYYYY").isAfter(
					moment().subtract(18, "years")
				)
			) {
				//Groom is under 18
				store.data.groom_under18 = true;
			} else {
				//Groom is NOT under 18
				store.data.groom_under18 = false;
			}
		} else {
			//Invalid Date. Assume groom is NOT under 18
			store.data.groom_under18 = false;
		}
		if (store.data.groom_under18 === false) {
			store.data.groom_courtname = "";
			store.data.groom_courtlocation = "";
			store.data.groom_courtdate_day = "";
			store.data.groom_courtdate_month = "";
			store.data.groom_courtdate_year = "";
			store.data.groom_consent = false;
			store.data.groom_courtapp = false;
		}
	} else if (
		name === "bride_dobd_day" ||
		name === "bride_dobd_month" ||
		name === "bride_dobd_year"
	) {
		day = store.data.bride_dobd_day;
		month = store.data.bride_dobd_month;
		year = store.data.bride_dobd_year;
		if (name === "bride_dobd_day") {
			day = value.value;
		} else if (name === "bride_dobd_month") {
			month = value.value;
		} else if (name === "bride_dobd_year") {
			year = value.value;
		}
		if (moment(day + month + year, "DDMMMMYYYY").isValid()) {
			if (
				parseInt(year) > 2000 &&
				moment(day + month + year, "DDMMMMYYYY").isAfter(
					moment().subtract(18, "years")
				)
			) {
				//Bride is under 18
				store.data.bride_under18 = true;
			} else {
				//Bride is NOT under 18
				store.data.bride_under18 = false;
			}
		} else {
			//Invalid Date. Assume bride is NOT under 18
			store.data.bride_under18 = false;
		}
		if (store.data.bride_under18 === false) {
			store.data.bride_courtname = "";
			store.data.bride_courtlocation = "";
			store.data.bride_courtdate_day = "";
			store.data.bride_courtdate_month = "";
			store.data.bride_courtdate_year = "";
			store.data.bride_consent = false;
			store.data.bride_courtapp = false;
		}
	}
	if (store.data.groom_birthplace_state_custom.length === 0) {
		store.data.groom_bornoutaus = false;
	} else {
		store.data.groom_bornoutaus = true;
	}
	if (store.data.bride_birthplace_state_custom.length === 0) {
		store.data.bride_bornoutaus = false;
	} else {
		store.data.bride_bornoutaus = true;
	}
	store.marriagedate_weekday = newWeekday;
	store.checkDataForChanges();
	store.checkDisabled();
};
function capitalize(str) {
	// s = s.toLowerCase();
	// return s && s[0].toUpperCase() + s.slice(1);
	return str.replace(/\S\S*/g, function (word) {
		// word = word.toLowerCase();
		return word.charAt(0).toUpperCase() + word.substr(1);
	});
}
// function capitalize_every_word(str)
// {
// 	str = str.split(" ");
// 	for (var i = 0, x = str.length; i < x; i++) {
// 		str[i] = str[i][0].toUpperCase() + str[i].substr(1);
// 	}
// 	return str.join(" ");
// }
function capitalize_every_word(str) {
	return str.replace(/\S\S*/g, function (word) {
		// word = word.toLowerCase();
		return word.charAt(0).toUpperCase() + word.substr(1);
	});
}
store.handleInputChange = function (event) {
	var name = event.target.name;
	var value = event.target.value;
	if (name === "paymenttotal") {
		value = Number(value).toString();
	}
	if (
		name === "groom_decplace" ||
		name === "bride_decplace" ||
		name === "marriageat1" ||
		name === "form_address" ||
		name === "groom_fathersname" ||
		name === "groom_father_birth_name" ||
		name === "bride_fathersname" ||
		name === "bride_father_birth_name" ||
		name === "groom_mothersname" ||
		name === "groom_mother_birth_name" ||
		name === "bride_mothersname" ||
		name === "bride_mother_birth_name" ||
		// name === "groom_surname" ||
		// name === "groom_firstandother" ||
		name === "groom_address" ||
		name === "groom_address2" ||
		name === "groom_city" ||
		name === "groom_state" ||
		name === "groom_state_selection" ||
		name === "groom_birthplace" ||
		// name === "bride_surname" ||
		// name === "bride_firstandother" ||
		name === "bride_address" ||
		name === "bride_address2" ||
		name === "bride_city" ||
		name === "bride_state" ||
		name === "bride_state_selection" ||
		name === "bride_birthplace"
	) {
		value = capitalize_every_word(value);
	}
	if (name === "marriageat1") {
		store.data.placemarriagesol = capitalize(value);
		if (store.data.marriageat1 === store.data.groom_decplace) {
			store.data.groom_decplace = capitalize(value);
		}
		if (store.data.marriageat1 === store.data.bride_decplace) {
			store.data.bride_decplace = capitalize(value);
		}
		if (store.data.marriageat1 === store.data.form_address) {
			store.data.form_address = capitalize(value);
		}
	}
	if (
		name === "bride_yearsresidancy" ||
		name === "bride_monthsresidancy" ||
		name === "groom_yearsresidancy" ||
		name === "groom_monthsresidancy"
	) {
		if (value && !isNaN(value)) {
			store.data[name] = parseInt(value, 10);
		} else {
			// store.data[name] = ""
		}
	} else {
		if (name === "bride_email" || name === "groom_email") {
			store.data[name] = value;
		} else {
			store.data[name] = capitalize(value);
		}
	}
	store.checkDataForChanges();
	store.checkDisabled();
};
store.handleTextArea = function (event) {
	store.data.notes = event.target.value;
	store.checkDataForChanges();
};
store.checkboxClicked = function (event) {
	store.data[event.target.name] = event.target.checked;
	store.checkDataForChanges();
};
store.toggle = function (tab) {
	if (store.activeItem !== tab) {
		store.activeItem = tab;
	}
};
store.saveClicked = function () {
	if (
		(store.publicDirty && store.mode === "P") ||
		(store.dirty && (store.mode === "E" || store.mode === "N"))
	) {
		store.saveButtonText = "SAVING...";
		var type;
		var link;
		if (store.mode === "E" || store.mode === "Z") {
			if (store.mode === "Z") {
				type = "public";
			} else {
				type = "member";
			}
			axios
				.post("/" + type + "/api/weddings/update-wedding", store.data)
				.then(function (response) {
					toast.success("Wedding Information Saved!", {
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: false,
						draggable: false,
						pauseOnHover: false,
					});
					if (response.status === 200) {
						store.originalData = JSON.parse(JSON.stringify(store.data));
						store.originalData.noimLink = fixLink(store.originalData.noimLink);
						store.originalData.decCertLink = fixLink(
							store.originalData.decCertLink
						);
						store.originalData.form15Link = fixLink(
							store.originalData.form15Link
						);
						store.originalData.confLodge = fixLink(
							store.originalData.confLodge
						);
						store.originalData.vows = fixLink(store.originalData.vows);
						store.originalData.orderOfCeremony = fixLink(
							store.originalData.orderOfCeremony
						);
						store.dirty = false;
						if (store.mode === "E") {
							store.saveButtonText = "SAVE";
						} else if (store.mode === "Z") {
							store.saveButtonText = "Confirm NOIM Information";
						}
					}
				})
				.catch(function (error) {
					Sentry.captureException(error);
					console.log(error);
					toast.error("ERROR! Wedding is locked.", {
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: false,
						draggable: false,
						pauseOnHover: false,
					});
					// window.location.replace("/");
				});
		} else if (store.mode === "P" || store.mode === "N") {
			if (store.mode === "P") {
				type = "new";
			} else {
				type = "member";
			}
			var toastText;
			link = "create-wedding";
			if (store.mode === "P") {
				toastText = "NOIM Updated!";
				store.finalModal = true;
			} else {
				toastText = "Wedding Created!";
			}
			if (store.mode === "P") {
				if (store.create_wedding === false) {
					link = "create-wedding";
				} else {
					link = "update-wedding";
					toastText = "NOIM Updated!";
				}
			}
			axios
				.post("/" + type + "/api/weddings/" + link, store.data)
				.then(function (response) {
					if (response.status === 200) {
						toast.success(toastText, {
							position: "top-right",
							autoClose: 3000,
							closeButton: false,
							hideProgressBar: false,
							draggable: false,
							pauseOnHover: false,
						});
						//The data is already set
						// store.data = JSON.parse(JSON.stringify(response.data[0]))
						store.data.noimLink = fixLink(response.data[0].noimLink);
						store.data.decCertLink = response.data[0].decCertLink;
						store.data.form15Link = response.data[0].form15Link;
						store.data.confLodge = response.data[0].confLodge;
						store.data.vows = response.data[0].vows;
						store.data.orderOfCeremony = response.data[0].orderOfCeremony;
						store.data.id = response.data[0].id;
						store.data.user_id = response.data[0].user_id;
						store.create_wedding = true;
						store.originalData = JSON.parse(JSON.stringify(response.data[0]));
						store.dirty = false;
						if (store.mode === "N") {
							store.saveButtonText = "SAVE";
							store.mode = "E";
						} else if (store.mode === "P") {
							store.saveButtonText = "Confirm NOIM Information";
						}
					} else {
						toast.error("ERROR! Please try again.", {
							position: "top-right",
							autoClose: 3000,
							closeButton: false,
							hideProgressBar: false,
							draggable: false,
							pauseOnHover: false,
						});
					}
				})
				.catch(function (error) {
					Sentry.captureException(error);
					console.log(error);
					// window.location.replace("/");
				});
		}
	}
};
store.deleteFile = function (event) {
	store.deleteModal = true;
	store.deleteFilename = event.target.name;
};
store.closeAll = function () {
	store.activeItem = "2";
	store.modal = false;
	store.finalModal = false;
	store.dirty = false;
	store.close();
	store.data = newWeddingData;
};
store.closeClicked = function () {
	if (store.dirty === true) {
		store.toggleModal();
	} else {
		store.close();
		store.activeItem = "2";
	}
};
store.saveAndPrint = function () {
	var win = window.open(store.data.noimLink, "_blank");
	win.focus();
	store.data = newWeddingData;
	store.dirty = false;
	store.publicDirty = false;
	store.create_wedding = false;
};

var WeddingDataClass = observer(
	class WeddingDataClass extends React.Component {
		constructor(props) {
			super(props);
			if (this.props.mode === "E" || this.props.mode === "P") {
				if (this.props.mode === "P") {
					store.saveButtonText = "Confirm NOIM Information";
				} else {
					store.saveButtonText = "SAVE";
				}
			} else if (this.props.mode === "N") {
				store.saveButtonText = "CREATE";
			}
			store.names = this.props.names;
			store.mode = this.props.mode;
			store.close = this.props.close;
			store.files = [];
			if (this.props.mode === "Z" || this.props.mode === "E") {
				store.loaded = false;
			} else {
				store.loaded = true;
			}
			// store.noimLink = ""
			// store.decCertLink = ""
			// store.form15Link = ""
			// store.noimNoCNLink = ""
			// store.decCertNoCNLink = ""
			if (this.props.mode === "N" || this.props.mode === "P") {
				store.data = newWeddingData;
				store.id = 0;
			} else {
				store.id = this.props.id;
			}
		}
		componentDidMount() {
			store.componentDidMount();
		}
		render() {
			var content;
			if (this.props.mode === "E" || this.props.mode === "N") {
				content = <MemberWedding />;
			} else if (this.props.mode === "Z" && store.locked === true) {
				content = <div></div>;
			} else {
				content = (
					<div>
						{store.loaded ? (
							<div>
								<NOIM />
								<SaveArea />
							</div>
						) : (
							<Row>
								<Col className="col-12 text-center">
									<Spinner blue big />
								</Col>
							</Row>
						)}
					</div>
				);
			}
			return (
				<div>
					{store.locked === true && store.mode === "Z" ? (
						<Row className="text-center">
							<Col>
								<h3>This wedding has been locked</h3>
							</Col>
						</Row>
					) : (
						<div></div>
					)}
					{content}
				</div>
			);
		}
	}
);

function fixLink(inputLink) {
	if (inputLink !== undefined) {
		var returnLink = inputLink.split("/");
		if (store.mode === "P") {
			//Do nothing
		} else {
			if (store.currentUser === 1) {
				returnLink[3] = "1";
			} else if (store.currentUser === 2) {
				returnLink[3] = "2";
			} else {
				//Do nothing
			}
		}
		returnLink = returnLink.join("/");
		return returnLink;
	} else {
		return inputLink;
	}
}

var NOIM = observer(
	class NOIM extends React.Component {
		render() {
			return (
				<div>
					<Row>
						<Col>
							{store.mode === "Z" || store.mode === "P" ? (
								<div
									id="invalidAlert"
									className="alert alert-secondary mt-0 mb-3"
									role="alert"
								>
									<div
										dangerouslySetInnerHTML={createMarkup(store.topMessage)}
									/>
								</div>
							) : (
								<div></div>
							)}
						</Col>
					</Row>
					<Row>
						<Col className="col-12">
							<CardHeader
								color="elegant-color-dark"
								tag="h3"
								className="text-center"
							>
								The following parties give notice of their intended marriage
								<h6 className="mt-4">
									Please ensure these details are consistent and match those listed on your birth certificate. Any discrepancies may cause delays to your chosen wedding date or additional paperwork charges.
								</h6>
							</CardHeader>
							<Card>
								<CardBody>
									<Row>
										<Col className="col-12">
											<label>
												Planned Marriage Date (If unknown, leave blank)
											</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-4">
											<SingleSelect
												defaultValue={store.data.planned_marriage_date_day}
												onChange={store.handleSelectChange}
												name="planned_marriage_date_day"
												options={dateDays}
											/>
										</Col>
										<Col className="col-4">
											<SingleSelect
												defaultValue={store.data.planned_marriage_date_month}
												onChange={store.handleSelectChange}
												name="planned_marriage_date_month"
												options={dateMonths}
											/>
										</Col>
										<Col className="col-4">
											<SingleSelect
												defaultValue={store.data.planned_marriage_date_year}
												onChange={store.handleSelectChange}
												name="planned_marriage_date_year"
												options={dateYears}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-12">
											<label>
												Planned Marriage Venue (If unknown, leave blank)
											</label>
										</Col>
									</Row>
									<Row>
										<Col>
											<InputField
												name="planned_marriage_other"
												onChange={store.handleInputChange}
												value={store.data.planned_marriage_other}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col className="col-6 text-center">
											<h3 className="text-center">
												<Badge
													color="rgba-black-strong"
													style={{ width: "100%" }}
												>
													Party 1
												</Badge>
											</h3>
										</Col>
										<Col className="col-6 text-center">
											<h3 className="text-center">
												<Badge
													color="rgba-black-strong"
													style={{ width: "100%" }}
												>
													Party 2
												</Badge>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Description</label>
										</Col>
										<Col className="col-6">
											<label>Description</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_desc}
												onChange={store.handleSelectChange}
												name="groom_desc"
												value={store.data.groom_desc}
												options={[
													{
														value: "G",
														label: "Groom",
													},
													{
														value: "B",
														label: "Bride",
													},
													{
														value: "P",
														label: "Partner",
													},
												]}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_desc}
												onChange={store.handleSelectChange}
												name="bride_desc"
												value={store.data.bride_desc}
												options={[
													{
														value: "B",
														label: "Bride",
													},
													{
														value: "G",
														label: "Groom",
													},
													{
														value: "P",
														label: "Partner",
													},
												]}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													Surname <i className="fa fa-question-circle"></i>
												</label>
												<div>Please put your FAMILY surname</div>
											</MDBTooltip>
										</Col>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													Surname <i className="fa fa-question-circle"></i>
												</label>
												<div>Please put your FAMILY surname</div>
											</MDBTooltip>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_surname"
												placeholder="Surname/Family name"
												value={store.data.groom_surname}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_surname"
												value={store.data.bride_surname}
												onChange={store.handleInputChange}
												placeholder="Surname/Family name"
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													First and Middle Name{" "}
													<i className="fa fa-question-circle"></i>
												</label>
												<div>
													As per your Identification provided for Marriage
												</div>
											</MDBTooltip>
										</Col>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													First and Middle Name{" "}
													<i className="fa fa-question-circle"></i>
												</label>
												<div>
													As per your Identification provided for Marriage
												</div>
											</MDBTooltip>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_firstandother"
												value={store.data.groom_firstandother}
												onChange={store.handleInputChange}
												placeholder="First and middle name"
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_firstandother"
												value={store.data.bride_firstandother}
												onChange={store.handleInputChange}
												placeholder="First and middle name"
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Sex</label>
										</Col>
										<Col className="col-6">
											<label>Sex</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_sex}
												onChange={store.handleSelectChange}
												name="groom_sex"
												value={store.data.groom_sex}
												options={[
													{
														value: "M",
														label: "Male",
													},
													{
														value: "F",
														label: "Female",
													},
													{
														value: "X",
														label: "X",
													},
												]}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_sex}
												onChange={store.handleSelectChange}
												name="bride_sex"
												value={store.data.bride_sex}
												options={[
													{
														value: "F",
														label: "Female",
													},
													{
														value: "M",
														label: "Male",
													},
													{
														value: "X",
														label: "X",
													},
												]}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													Occupation <i className="fa fa-question-circle"></i>
												</label>
												<div>
													If Unemployed, put 'Unemployed'. If studying put
													'Student'. If at stay at home parent put 'Home
													Duties'. If Retired put 'Retired - Occupation'
												</div>
											</MDBTooltip>
										</Col>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													Occupation <i className="fa fa-question-circle"></i>
												</label>
												<div>
													If Unemployed, put 'Unemployed'. If studying put
													'Student'. If at stay at home parent put 'Home
													Duties'. If Retired put 'Retired - Occupation'
												</div>
											</MDBTooltip>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_occupation"
												value={store.data.groom_occupation}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_occupation"
												value={store.data.bride_occupation}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Email Address</label>
										</Col>
										<Col className="col-6">
											<label>Email Address</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_email"
												value={store.data.groom_email}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_email"
												value={store.data.bride_email}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Mobile Number</label>
										</Col>
										<Col className="col-6">
											<label>Mobile Number</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_number"
												value={store.data.groom_number}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_number"
												value={store.data.bride_number}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col className="col-6 text-center">
											<h3 className="text-center">
												<Button
													onClick={store.copyAddressFromParty2}
													className="btn-dark btn-block"
													color="unique-color"
												>
													COPY ADDRESS FROM PARTY 2
												</Button>
											</h3>
										</Col>
										<Col className="col-6 text-center">
											<h3 className="text-center">
												<Button
													onClick={store.copyAddressFromParty1}
													className="btn-dark btn-block"
													color="unique-color"
												>
													COPY ADDRESS FROM PARTY 1
												</Button>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Address (1)</label>
										</Col>
										<Col className="col-6">
											<label>Address (1)</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_address"
												value={store.data.groom_address}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_address"
												value={store.data.bride_address}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Address (2)</label>
										</Col>
										<Col className="col-6">
											<label>Address (2)</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_address2"
												value={store.data.groom_address2}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_address2"
												value={store.data.bride_address2}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>City</label>
										</Col>
										<Col className="col-6">
											<label>City</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_city"
												value={store.data.groom_city}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_city"
												value={store.data.bride_city}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Country</label>
										</Col>
										<Col className="col-6">
											<label>Country</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_state}
												onChange={store.handleSelectChange}
												name="groom_state"
												options={countryList}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_state}
												onChange={store.handleSelectChange}
												name="bride_state"
												options={countryList}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>State</label>
										</Col>
										<Col className="col-6">
											<label>State</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_state_selection}
												onChange={store.handleSelectChange}
												name="groom_state_selection"
												disabled={store.data.groom_state.length !== 0}
												options={australianStates}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_state_selection}
												onChange={store.handleSelectChange}
												name="bride_state_selection"
												disabled={store.data.bride_state.length !== 0}
												options={australianStates}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Conjugal Status</label>
										</Col>
										<Col className="col-6">
											<label>Conjugal Status</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_history}
												onChange={store.handleSelectChange}
												name="groom_history"
												options={conjugalOptions}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_history}
												onChange={store.handleSelectChange}
												name="bride_history"
												options={conjugalOptions}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													Court Location of Divorce{" "}
													<i className="fa fa-question-circle"></i>
												</label>
												<div>
													City in which the divorce was granted - As per divorce
													paperwork
												</div>
											</MDBTooltip>
										</Col>
										<Col className="col-6">
											<MDBTooltip placement="top" tag="div" domElement>
												<label>
													Court Location of Divorce{" "}
													<i className="fa fa-question-circle"></i>
												</label>
												<div>
													City in which the divorce was granted - As per divorce
													paperwork
												</div>
											</MDBTooltip>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_courtlocation"
												value={store.data.groom_courtlocation}
												onChange={store.handleInputChange}
												disabled={
													store.data.groom_history !== "Divorced" &&
													store.data.groom_history !==
													"Married - Divorce Pending"
												}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_courtlocation"
												value={store.data.bride_courtlocation}
												onChange={store.handleInputChange}
												disabled={
													store.data.bride_history !== "Divorced" &&
													store.data.bride_history !==
													"Married - Divorce Pending"
												}
											/>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col className="col-12 text-center">
											<h3 className="text-center">
												<Badge
													color="rgba-black-strong"
													style={{ width: "100%" }}
												>
													Birth Details must match details on your Birth Certificate and/or Passport
												</Badge>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Birthplace City</label>
										</Col>
										<Col className="col-6">
											<label>Birthplace City</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_birthplace"
												value={store.data.groom_birthplace}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_birthplace"
												value={store.data.bride_birthplace}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Birthplace Country</label>
										</Col>
										<Col className="col-6">
											<label>Birthplace Country</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_birthplace_state_custom}
												onChange={store.handleSelectChange}
												name="groom_birthplace_state_custom"
												options={countryList}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_birthplace_state_custom}
												onChange={store.handleSelectChange}
												name="bride_birthplace_state_custom"
												options={countryList}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Birthplace State</label>
										</Col>
										<Col className="col-6">
											<label>Birthplace State</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_birthplace_state}
												onChange={store.handleSelectChange}
												name="groom_birthplace_state"
												disabled={
													store.data.groom_birthplace_state_custom.length !== 0
												}
												options={australianStates}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_birthplace_state}
												onChange={store.handleSelectChange}
												name="bride_birthplace_state"
												disabled={
													store.data.bride_birthplace_state_custom.length !== 0
												}
												options={australianStates}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Date of Birth</label>
										</Col>
										<Col className="col-6">
											<label>Date of Birth</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_dobd_day}
												onChange={store.handleSelectChange}
												name="groom_dobd_day"
												options={dateDays}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_dobd_day}
												onChange={store.handleSelectChange}
												name="bride_dobd_day"
												options={dateDays}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_dobd_month}
												onChange={store.handleSelectChange}
												name="groom_dobd_month"
												options={dateMonths}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_dobd_month}
												onChange={store.handleSelectChange}
												name="bride_dobd_month"
												options={dateMonths}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_dobd_year}
												onChange={store.handleSelectChange}
												name="groom_dobd_year"
												options={dateDOBYears}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_dobd_year}
												onChange={store.handleSelectChange}
												name="bride_dobd_year"
												options={dateDOBYears}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Born Outside of Australia?</label>
										</Col>
										<Col className="col-6">
											<label>Born Outside of Australia?</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_bornoutaus}
												onChange={store.handleSelectChange}
												name="groom_bornoutaus"
												value={store.data.groom_bornoutaus}
												disabled={true}
												options={yesNoOptions}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_bornoutaus}
												onChange={store.handleSelectChange}
												name="bride_bornoutaus"
												value={store.data.bride_bornoutaus}
												disabled={true}
												options={yesNoOptions}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Total Number of Years in Australia</label>
										</Col>
										<Col className="col-6">
											<label>Total Number of Years in Australia</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_yearsresidancy"
												value={store.data.groom_yearsresidancy}
												onChange={store.handleInputChange}
												disabled={store.disableGroomBornOut}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_yearsresidancy"
												value={store.data.bride_yearsresidancy}
												onChange={store.handleInputChange}
												disabled={store.disableBrideBornOut}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Total Number of Months in Australia</label>
										</Col>
										<Col className="col-6">
											<label>Total Number of Months in Australia</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_monthsresidancy}
												onChange={store.handleSelectChange}
												name="groom_monthsresidancy"
												disabled={store.disableGroomBornOut}
												options={numberOfMonths}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_monthsresidancy}
												onChange={store.handleSelectChange}
												name="bride_monthsresidancy"
												disabled={store.disableBrideBornOut}
												options={numberOfMonths}
											/>
										</Col>
									</Row>
									<Row className="text-center w-100">
										<Col className="col-12 my-4 text-center w-100">
											<Row className="text-center w-100">
												<h3 className="fw-lighter text-center w-100">
													Please note, in Australia, names are noted as First name, then last name.
												</h3>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Fathers Current Name in Full (Parent 1)
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.groom_fatherdeceased === true}
												type="checkbox"
												id="checkbox1"
												name="groom_fatherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Fathers Current Name in Full (Parent 1)
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.bride_fatherdeceased === true}
												type="checkbox"
												id="checkbox2"
												name="bride_fatherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_fathersname"
												value={store.data.groom_fathersname}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_fathersname"
												value={store.data.bride_fathersname}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
									</Row>

									<Row>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Fathers Birth Name in Full (Parent 1)
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.groom_fatherdeceased === true}
												type="checkbox"
												id="checkbox1"
												name="groom_fatherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Fathers Birth Name in Full (Parent 1)
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.bride_fatherdeceased === true}
												type="checkbox"
												id="checkbox2"
												name="bride_fatherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
									</Row>

									<Row>
										<Col className="col-6">
											<InputField
												name="groom_father_birth_name"
												value={store.data.groom_father_birth_name}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_father_birth_name"
												value={store.data.bride_father_birth_name}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Fathers Country of Birth (Parent 1)</label>
										</Col>
										<Col className="col-6">
											<label>Fathers Country of Birth (Parent 1)</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_fathercob}
												onChange={store.handleSelectChange}
												name="groom_fathercob"
												options={countryList}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_fathercob}
												onChange={store.handleSelectChange}
												name="bride_fathercob"
												options={countryList}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Mothers Current Name in Full (Parent 2)
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.groom_motherdeceased === true}
												type="checkbox"
												id="checkbox3"
												name="groom_motherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Mothers Current Name in Full (Parent 2)
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.bride_motherdeceased === true}
												type="checkbox"
												id="checkbox4"
												name="bride_motherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_mothersname"
												value={store.data.groom_mothersname}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_mothersname"
												value={store.data.bride_mothersname}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
									</Row>

									<Row>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Mothers Birth (maiden) Name in Full (Parent 2)  – as listed on your birth certificate.
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.groom_fatherdeceased === true}
												type="checkbox"
												id="checkbox1"
												name="groom_fatherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
										<Col className="col-6">
											<FormInline>
												<label className="mr-2">
													Mothers Birth (maiden) Name in Full (Parent 2)  – as listed on your birth certificate.
												</label>
												{/* <Input
												label="Deceased?"
												containerClass="ml-3"
												checked={store.data.bride_fatherdeceased === true}
												type="checkbox"
												id="checkbox2"
												name="bride_fatherdeceased"
												onChange={store.checkboxClicked}
											/> */}
											</FormInline>
										</Col>
									</Row>

									<Row>
										<Col className="col-6">
											<InputField
												name="groom_mother_birth_name"
												value={store.data.groom_mother_birth_name}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_mother_birth_name"
												value={store.data.bride_mother_birth_name}
												onChange={store.handleInputChange}
												disabled={false}
												placeholder="First Middle Last"
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Mothers Country of Birth (Parent 2)</label>
										</Col>
										<Col className="col-6">
											<label>Mothers Country of Birth (Parent 2)</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.groom_mothercob}
												onChange={store.handleSelectChange}
												name="groom_mothercob"
												options={countryList}
											/>
										</Col>
										<Col className="col-6">
											<SingleSelect
												defaultValue={store.data.bride_mothercob}
												onChange={store.handleSelectChange}
												name="bride_mothercob"
												options={countryList}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col className="col-12">
							<CardHeader
								color="elegant-color-dark"
								tag="h3"
								className="text-center"
							>
								Witness
							</CardHeader>
							<Card>
								<CardBody>
									<Row className="mt-3">
										<Col className="col-12 text-center">
											<h3 className="text-center">
												<Badge
													color="rgba-black-strong"
													style={{ width: "100%" }}
												>
													Must be over the age of 18
												</Badge>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Witness 1 First & Last Name</label>
										</Col>
										<Col className="col-6">
											<label>Witness 2 First & Last Name</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="witness_1_no_form"
												value={store.data.witness_1_no_form}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="witness_2_no_form"
												value={store.data.witness_2_no_form}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>




					<Row>
						<Col className="col-12">
							<CardHeader
								color="elegant-color-dark"
								tag="h3"
								className="text-center"
							>
								Identification for Marriage
							</CardHeader>
							<Card>
								<CardBody>
									<Row>
										<Col className="col-12 text-center">
											<h4>
												A Passport is the preferred option for identification.
											</h4>
											<p></p>
											<h4>
												If a Passport is not available then a Birth Certificate
												and valid Photo ID will be required.
											</h4>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col className="col-6 text-center">
											<h3 className="text-center">
												<Badge
													color="rgba-black-strong"
													style={{ width: "100%" }}
												>
													Party 1
												</Badge>
											</h3>
										</Col>
										<Col className="col-6 text-center">
											<h3 className="text-center">
												<Badge
													color="rgba-black-strong"
													style={{ width: "100%" }}
												>
													Party 2
												</Badge>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Australian/Overseas Passport Number</label>
										</Col>
										<Col className="col-6">
											<label>Australian/Overseas Passport Number</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_overseaspass"
												value={store.data.groom_overseaspass}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_overseaspass"
												value={store.data.bride_overseaspass}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
									<Row>
										<Col className="col-6 text-center">
											<h2>OR</h2>
										</Col>
										<Col className="col-6 text-center">
											<h2>OR</h2>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<label>Birth Certificate Registration Number</label>
										</Col>
										<Col className="col-6">
											<label>Birth Certificate Registration Number</label>
										</Col>
									</Row>
									<Row>
										<Col className="col-6">
											<InputField
												name="groom_regnum"
												value={store.data.groom_regnum}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
										<Col className="col-6">
											<InputField
												name="bride_regnum"
												value={store.data.bride_regnum}
												onChange={store.handleInputChange}
												disabled={false}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col className="mt-4">
							<Card className="h-100">
								<CardHeader
									color="elegant-color-dark"
									tag="h3"
									className="text-center"
								>
									Parties Related?
								</CardHeader>
								<CardBody>
									<Row>
										<Col className="col-12 col-md-4">
											<label>Are the Parties Related?</label>
											<SingleSelect
												defaultValue={store.data.partiesrelated}
												onChange={store.handleSelectChange}
												name="partiesrelated"
												options={yesNoOptions}
											/>
										</Col>
										<Col className="col-12 col-md-8">
											<label>Whats the Relationship?</label>
											<InputField
												name="relationship"
												value={store.data.relationship}
												onChange={store.handleInputChange}
												disabled={store.data.partiesrelated === false}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						{store.data.groom_history !== "Never Validly Married" ||
							store.data.bride_history !== "Never Validly Married" ? (
							<Col className="mt-4">
								<Card className="h-100">
									<CardHeader
										color="elegant-color-dark"
										tag="h3"
										className="text-center"
									>
										Previously Married
									</CardHeader>
									<CardBody>
										<Row>
											<Col className="col-6 text-center">
												<h3 className="text-center">
													<Badge
														color="rgba-black-strong"
														style={{ width: "100%" }}
													>
														Party 1
													</Badge>
												</h3>
											</Col>
											<Col className="col-6 text-center">
												<h3 className="text-center">
													<Badge
														color="rgba-black-strong"
														style={{ width: "100%" }}
													>
														Party 2
													</Badge>
												</h3>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<label>Number of Previous Marriages</label>
											</Col>
											<Col className="col-6">
												<label>Number of Previous Marriages</label>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.groom_previousmarriages}
													onChange={store.handleSelectChange}
													name="groom_previousmarriages"
													value={store.data.groom_previousmarriages}
													options={marriageCount}
												/>
											</Col>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.bride_previousmarriages}
													onChange={store.handleSelectChange}
													name="bride_previousmarriages"
													value={store.data.bride_previousmarriages}
													options={marriageCount}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<label>Year of Each Previous Marriage Ceremony</label>
											</Col>
											<Col className="col-6">
												<label>Year of Each Previous Marriage Ceremony</label>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<InputField
													name="groom_yearspreviousmarriages"
													value={store.data.groom_yearspreviousmarriages}
													onChange={store.handleInputChange}
													disabled={store.disableGroomPreviousMarriages}
												/>
											</Col>
											<Col className="col-6">
												<InputField
													name="bride_yearspreviousmarriages"
													value={store.data.bride_yearspreviousmarriages}
													onChange={store.handleInputChange}
													disabled={store.disableBridePreviousMarriages}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<label>
													Number of Children from Previous Marriages
												</label>
											</Col>
											<Col className="col-6">
												<label>
													Number of Children from Previous Marriages
												</label>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<InputField
													name="groom_numberchildprevmarriagebornalive"
													value={
														store.data.groom_numberchildprevmarriagebornalive
													}
													onChange={store.handleInputChange}
													disabled={store.disableGroomPreviousMarriages}
												/>
											</Col>
											<Col className="col-6">
												<InputField
													name="bride_numberchildprevmarriagebornalive"
													value={
														store.data.bride_numberchildprevmarriagebornalive
													}
													onChange={store.handleInputChange}
													disabled={store.disableBridePreviousMarriages}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<label>Year of Birth of Each Child</label>
											</Col>
											<Col className="col-6">
												<label>Year of Birth of Each Child</label>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<InputField
													name="groom_yearbirtheachchild"
													value={store.data.groom_yearbirtheachchild}
													onChange={store.handleInputChange}
													disabled={store.disableGroomPreviousMarriages}
												/>
											</Col>
											<Col className="col-6">
												<InputField
													name="bride_yearbirtheachchild"
													value={store.data.bride_yearbirtheachchild}
													onChange={store.handleInputChange}
													disabled={store.disableBridePreviousMarriages}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<label>How Last Marriage Ended</label>
											</Col>
											<Col className="col-6">
												<label>How Last Marriage Ended</label>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.groom_howlastmarriageend}
													onChange={store.handleSelectChange}
													name="groom_howlastmarriageend"
													value={store.data.groom_howlastmarriageend}
													disabled={store.disableGroomPreviousMarriages}
													options={lastMarriageEndOptions}
												/>
											</Col>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.bride_howlastmarriageend}
													onChange={store.handleSelectChange}
													name="bride_howlastmarriageend"
													value={store.data.bride_howlastmarriageend}
													disabled={store.disableBridePreviousMarriages}
													options={lastMarriageEndOptions}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<label>Date Last Marriage Ended</label>
											</Col>
											<Col className="col-6">
												<label>Date Last Marriage Ended</label>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.groom_lastd_day}
													onChange={store.handleSelectChange}
													name="groom_lastd_day"
													disabled={store.disableGroomPreviousMarriages}
													options={dateDays}
												/>
											</Col>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.bride_lastd_day}
													onChange={store.handleSelectChange}
													name="bride_lastd_day"
													disabled={store.disableBridePreviousMarriages}
													options={dateDays}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.groom_lastd_month}
													onChange={store.handleSelectChange}
													name="groom_lastd_month"
													disabled={store.disableGroomPreviousMarriages}
													options={dateMonths}
												/>
											</Col>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.bride_lastd_month}
													onChange={store.handleSelectChange}
													name="bride_lastd_month"
													disabled={store.disableBridePreviousMarriages}
													options={dateMonths}
												/>
											</Col>
										</Row>
										<Row>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.groom_lastd_year}
													onChange={store.handleSelectChange}
													name="groom_lastd_year"
													disabled={store.disableGroomPreviousMarriages}
													options={dateDOBYears}
												/>
											</Col>
											<Col className="col-6">
												<SingleSelect
													defaultValue={store.data.bride_lastd_year}
													onChange={store.handleSelectChange}
													name="bride_lastd_year"
													disabled={store.disableBridePreviousMarriages}
													options={dateDOBYears}
												/>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						) : (
							<div></div>
						)}
					</Row>
					<Row>
						<Col>
							{store.mode === "Z" || store.mode === "P" ? (
								<div
									id="invalidAlert"
									className="alert alert-secondary mt-3 mb-3"
									role="alert"
								>
									<div
										dangerouslySetInnerHTML={createMarkup(store.bottomMessage)}
									/>
								</div>
							) : (
								<div></div>
							)}
						</Col>
					</Row>
				</div>
			);
		}
	}
);

// <Row className="mt-5">
// <Col className="col-12">
//   <CardHeader color="elegant-color-dark" tag="h3" className="text-center">Evidence</CardHeader>
//   <Card>
//     <CardBody>
//       <Row className="mt-3">
//         <Col className="col-6 text-center">
//           <h3 className="text-center"><Badge color="rgba-black-strong" style={{width: "100%"}}>Party 1</Badge></h3>
//         </Col>
//         <Col className="col-6 text-center">
//           <h3 className="text-center"><Badge color="rgba-black-strong" style={{width: "100%"}}>Party 2</Badge></h3>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <label>
//             Evidence (Death)?
//           </label>
//         </Col>
//         <Col className="col-6">
//           <label>
//             Evidence (Death)?
//           </label>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <SingleSelect
//             defaultValue={store.data.groom_evidence}
//             onChange={store.handleSelectChange}
//             name="groom_evidence"
//             value={store.data.groom_evidence}
//             options={yesNoOptions} />
//         </Col>
//         <Col className="col-6">
//           <SingleSelect
//             defaultValue={store.data.bride_evidence}
//             onChange={store.handleSelectChange}
//             name="bride_evidence"
//             value={store.data.bride_evidence}
//             options={yesNoOptions} />
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <label>
//             Evidence (Nullity)?
//           </label>
//         </Col>
//         <Col className="col-6">
//           <label>
//             Evidence (Nullity)?
//           </label>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <SingleSelect
//             defaultValue={store.data.groom_evidencen}
//             onChange={store.handleSelectChange}
//             name="groom_evidencen"
//             value={store.data.groom_evidencen}
//             options={yesNoOptions} />
//         </Col>
//         <Col className="col-6">
//           <SingleSelect
//             defaultValue={store.data.bride_evidencen}
//             onChange={store.handleSelectChange}
//             name="bride_evidencen"
//             value={store.data.bride_evidencen}
//             options={yesNoOptions} />
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <label>
//             Evidence (Dissolution)?
//           </label>
//         </Col>
//         <Col className="col-6">
//           <label>
//             Evidence (Dissolution)?
//           </label>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <SingleSelect
//             defaultValue={store.data.groom_evidences}
//             onChange={store.handleSelectChange}
//             name="groom_evidences"
//             value={store.data.groom_evidences}
//             options={yesNoOptions} />
//         </Col>
//         <Col className="col-6">
//           <SingleSelect
//             defaultValue={store.data.bride_evidences}
//             onChange={store.handleSelectChange}
//             name="bride_evidences"
//             value={store.data.bride_evidences}
//             options={yesNoOptions} />
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <label>
//             Court Location 1 (Nullity or Dissolution):
//           </label>
//         </Col>
//         <Col className="col-6">
//           <label>
//             Court Location 2 (Nullity or Dissolution):
//           </label>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="col-6">
//           <InputField
//             name="court1"
//             value={store.data.court1}
//             onChange={store.handleInputChange}
//             disabled={false}
//           />
//         </Col>
//         <Col className="col-6">
//           <InputField
//             name="court2"
//             value={store.data.court2}
//             onChange={store.handleInputChange}
//             disabled={false}
//           />
//         </Col>
//       </Row>
//       <label>
//         Authority for Marriage Despite Late Notice
//       </label>
//       <Row>
//         <Col>
//           <SingleSelect
//             defaultValue={store.data.approvalshort}
//             onChange={store.handleSelectChange}
//             name="approvalshort"
//             value={store.data.approvalshort}
//             options={yesNoOptions} />
//         </Col>
//       </Row>
//     </CardBody>
//   </Card>
// </Col>
// </Row>

// <Row>
// <Col className="col-6">
//   <label>
//     Produced Passport?
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     Produced Passport?
//   </label>
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.groom_pass_produced}
//     onChange={store.handleSelectChange}
//     name="groom_pass_produced"
//     value={store.data.groom_pass_produced}
//     options={yesNoOptions} />
// </Col>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.bride_pass_produced}
//     onChange={store.handleSelectChange}
//     name="bride_pass_produced"
//     value={store.data.bride_pass_produced}
//     options={yesNoOptions} />
// </Col>
// </Row>

// <Row>
// <Col className="col-6">
//   <label>
//     Produced Birth Certificate?
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     Produced Birth Certificate?
//   </label>
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.groom_regnum_produced}
//     onChange={store.handleSelectChange}
//     name="groom_regnum_produced"
//     value={store.data.groom_regnum_produced}
//     options={yesNoOptions} />
// </Col>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.bride_regnum_produced}
//     onChange={store.handleSelectChange}
//     name="bride_regnum_produced"
//     value={store.data.bride_regnum_produced}
//     options={yesNoOptions} />
// </Col>
// </Row>

// <Row>
// <Col className="col-6">
//   <label>
//     #Produced Current Drivers Licence?
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     #Produced Current Drivers Licence?
//   </label>
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.groom_licence}
//     onChange={store.handleSelectChange}
//     name="groom_licence"
//     value={store.data.groom_licence}
//     options={yesNoOptions} />
// </Col>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.bride_licence}
//     onChange={store.handleSelectChange}
//     name="bride_licence"
//     value={store.data.bride_licence}
//     options={yesNoOptions} />
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <label>
//     Drivers Licence Number
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     Drivers Licence Number
//   </label>
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <InputField
//     name="groom_licencenum"
//     value={store.data.groom_licencenum}
//     onChange={store.handleInputChange}
//     disabled={store.data.groom_licence === false}
//   />
// </Col>
// <Col className="col-6">
//   <InputField
//     name="bride_licencenum"
//     value={store.data.bride_licencenum}
//     onChange={store.handleInputChange}
//     disabled={store.data.bride_licence === false}
//   />
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <label>
//     #Produced Current Proof of Age Card?
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     #Produced Current Proof of Age Card?
//   </label>
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.groom_proofage}
//     onChange={store.handleSelectChange}
//     name="groom_proofage"
//     value={store.data.groom_proofage}
//     options={yesNoOptions} />
// </Col>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.bride_proofage}
//     onChange={store.handleSelectChange}
//     name="bride_proofage"
//     value={store.data.bride_proofage}
//     options={yesNoOptions} />
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <label>
//     Proof of Age Number
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     Proof of Age Number
//   </label>
// </Col>
// </Row>
// <Row>
// <Col className="col-6">
//   <InputField
//     name="groom_proofagenum"
//     value={store.data.groom_proofagenum}
//     onChange={store.handleInputChange}
//     disabled={store.data.groom_proofage === false}
//   />
// </Col>
// <Col className="col-6">
//   <InputField
//     name="bride_proofagenum"
//     value={store.data.bride_proofagenum}
//     onChange={store.handleInputChange}
//     disabled={store.data.bride_proofage === false}
//   />
// </Col>
// </Row>
// {store.mode === "N" || store.mode === "E" ?
// <Row>
// <Col className="col-6">
//   <label>
//     #Produced Current Identification With Paragraph?
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     #Produced Current Identification With Paragraph?
//   </label>
// </Col>
// </Row>
// :
// <div></div>
// }
// {store.mode === "N" || store.mode === "E" ?
// <Row>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.groom_currentiden}
//     onChange={store.handleSelectChange}
//     name="groom_currentiden"
//     value={store.data.groom_currentiden}
//     options={yesNoOptions} />
// </Col>
// <Col className="col-6">
//   <SingleSelect
//     defaultValue={store.data.bride_currentiden}
//     onChange={store.handleSelectChange}
//     name="bride_currentiden"
//     value={store.data.bride_currentiden}
//     options={yesNoOptions} />
// </Col>
// </Row>
// :
// <div></div>
// }
// {store.mode === "N" || store.mode === "E" ?
// <Row>
// <Col className="col-6">
//   <label>
//     Current Identification Type
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     Current Identification Type
//   </label>
// </Col>
// </Row>
// :
// <div></div>
// }
// {store.mode === "N" || store.mode === "E" ?
// <Row>
// <Col className="col-6">
//   <InputField
//     name="groom_currentidentype"
//     value={store.data.groom_currentidentype}
//     onChange={store.handleInputChange}
//     disabled={store.data.groom_currentiden === false}
//   />
// </Col>
// <Col className="col-6">
//   <InputField
//     name="bride_currentidentype"
//     value={store.data.bride_currentidentype}
//     onChange={store.handleInputChange}
//     disabled={store.data.bride_currentiden === false}
//   />
// </Col>
// </Row>
// :
// <div></div>
// }
// {store.mode === "N" || store.mode === "E" ?
// <Row>
// <Col className="col-6">
//   <label>
//     Current Identification Number
//   </label>
// </Col>
// <Col className="col-6">
//   <label>
//     Current Identification Number
//   </label>
// </Col>
// </Row>
// :
// <div></div>
// }
// {store.mode === "N" || store.mode === "E" ?
// <Row>
// <Col className="col-6">
//   <InputField
//     name="groom_currentidennum"
//     value={store.data.groom_currentidennum}
//     onChange={store.handleInputChange}
//     disabled={store.data.groom_currentiden === false}
//   />
// </Col>
// <Col className="col-6">
//   <InputField
//     name="bride_currentidennum"
//     value={store.data.bride_currentidennum}
//     onChange={store.handleInputChange}
//     disabled={store.data.bride_currentiden === false}
//   />
// </Col>
// </Row>
// :
// <div></div>
// }

// {(store.data.groom_under18 || store.data.bride_under18) ?
//   <Col lg="6" className="mt-4">
//     <Card className="h-100">
//       <CardHeader color="elegant-color-dark" tag="h3" className="text-center">Under 18</CardHeader>
//       <CardBody>
//           <Row>
//             <Col className="col-6 text-center">
//               <h3 className="text-center"><Badge color="rgba-black-strong" style={{width: "100%"}}>Party 1</Badge></h3>
//             </Col>
//             <Col className="col-6 text-center">
//               <h3 className="text-center"><Badge color="rgba-black-strong" style={{width: "100%"}}>Party 2</Badge></h3>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <label>
//                 Under 18?
//               </label>
//             </Col>
//             <Col className="col-6">
//               <label>
//                 Under 18?
//               </label>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <InputField
//                 name="groom_under18"
//                 value={(store.data.groom_under18 ? "Yes" : "No")}
//                 onChange={store.handleInputChange}
//                 disabled={true}
//               />
//             </Col>
//             <Col className="col-6">
//               <InputField
//                 name="bride_under18"
//                 value={(store.data.bride_under18 ? "Yes" : "No")}
//                 onChange={store.handleInputChange}
//                 disabled={true}
//               />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <label>
//                 Court Name
//               </label>
//             </Col>
//             <Col className="col-6">
//               <label>
//                 Court Name
//               </label>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <InputField
//                 name="groom_courtname"
//                 value={store.data.groom_courtname}
//                 onChange={store.handleInputChange}
//                 disabled={store.disableGroomUnder18}
//               />
//             </Col>
//             <Col className="col-6">
//               <InputField
//                 name="bride_courtname"
//                 value={store.data.bride_courtname}
//                 disabled={store.disableBrideUnder18}
//                 onChange={store.handleInputChange}
//               />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <label>
//                 Court Location
//               </label>
//             </Col>
//             <Col className="col-6">
//               <label>
//                 Court Location
//               </label>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <InputField
//                 name="groom_courtlocation"
//                 value={store.data.groom_courtlocation}
//                 onChange={store.handleInputChange}
//                 disabled={store.disableGroomUnder18}
//               />
//             </Col>
//             <Col className="col-6">
//               <InputField
//                 name="bride_courtlocation"
//                 value={store.data.bride_courtlocation}
//                 disabled={store.disableBrideUnder18}
//                 onChange={store.handleInputChange}
//               />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <label>
//                 Court Date
//               </label>
//             </Col>
//             <Col className="col-6">
//               <label>
//                 Court Date
//               </label>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.groom_courtdate_day}
//                 onChange={store.handleSelectChange}
//                 name="groom_courtdate_day"
//                 disabled={store.disableGroomUnder18}
//                 options={dateDays} />
//             </Col>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.bride_courtdate_day}
//                 onChange={store.handleSelectChange}
//                 name="bride_courtdate_day"
//                 disabled={store.disableBrideUnder18}
//                 options={dateDays} />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.groom_courtdate_month}
//                 onChange={store.handleSelectChange}
//                 name="groom_courtdate_month"
//                 disabled={store.disableGroomUnder18}
//                 options={dateMonths} />
//             </Col>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.bride_courtdate_month}
//                 onChange={store.handleSelectChange}
//                 name="bride_courtdate_month"
//                 disabled={store.disableBrideUnder18}
//                 options={dateMonths} />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.groom_courtdate_year}
//                 onChange={store.handleSelectChange}
//                 name="groom_courtdate_year"
//                 disabled={store.disableGroomUnder18}
//                 options={dateYears} />
//             </Col>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.bride_courtdate_year}
//                 onChange={store.handleSelectChange}
//                 name="bride_courtdate_year"
//                 disabled={store.disableBrideUnder18}
//                 options={dateYears} />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <label>
//                 Under 18, Consent Received?
//               </label>
//             </Col>
//             <Col className="col-6">
//               <label>
//                 Under 18, Consent Received?
//               </label>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.groom_consent}
//                 onChange={store.handleSelectChange}
//                 name="groom_consent"
//                 value={store.data.groom_consent}
//                 disabled={store.disableGroomUnder18}
//                 options={yesNoOptions} />
//             </Col>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.bride_consent}
//                 onChange={store.handleSelectChange}
//                 name="bride_consent"
//                 value={store.data.bride_consent}
//                 disabled={store.disableBrideUnder18}
//                 options={yesNoOptions} />
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <label>
//                 Under 18, Court Approval?
//               </label>
//             </Col>
//             <Col className="col-6">
//               <label>
//                 Under 18, Court Approval?
//               </label>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.groom_courtapp}
//                 onChange={store.handleSelectChange}
//                 name="groom_courtapp"
//                 value={store.data.groom_courtapp}
//                 disabled={store.disableGroomUnder18}
//                 options={yesNoOptions} />
//             </Col>
//             <Col className="col-6">
//               <SingleSelect
//                 defaultValue={store.data.bride_courtapp}
//                 onChange={store.handleSelectChange}
//                 name="bride_courtapp"
//                 value={store.data.bride_courtapp}
//                 disabled={store.disableBrideUnder18}
//                 options={yesNoOptions} />
//             </Col>
//           </Row>
//       </CardBody>
//     </Card>
//   </Col>
//   : <div></div>}

var MemberWedding = observer(
	class MemberWedding extends React.Component {
		render() {
			var files;
			if (store.files.length === 0) {
				files = (
					<Row>
						<Col className="text-center">
							<h4>No files found</h4>
						</Col>
					</Row>
				);
			} else {
				const columns = [
					{
						label: "File",
						field: "file",
						sort: "asc",
					},
					{
						label: "Download",
						field: "download",
						sort: "asc",
					},
					{
						label: "Delete",
						field: "delete",
						sort: "asc",
					},
				];
				const rowData = [];
				store.files.forEach(function (element) {
					rowData.push({
						file: element.filename,
						download: (
							<a
								className="btn btn-primary"
								target="_target"
								size="sm"
								name={element.filename}
								href={
									"/member/api/download-file/" +
									store.data.id +
									"/" +
									element.filename.toLowerCase()
								}
							>
								Download
							</a>
						),
						delete: (
							<button
								className="btn btn-danger"
								size="sm"
								name={element.filename}
								onClick={store.deleteFile}
							>
								Delete
							</button>
						),
					});
				});
				files = (
					<MDBTable btn>
						<MDBTableHead columns={columns} />
						<MDBTableBody rows={rowData} />
					</MDBTable>
				);
			}
			return (
				<Row id="weddingData">
					{!store.loaded ? (
						<Col className="col-12">
							<Row>
								<Col className="col-12 text-center">
									<Spinner blue big />
								</Col>
							</Row>
							<Row className="pt-3">
								<Col className="col-12 text-center">
									<Button
										className="btn btn-lg"
										color="success"
										onClick={store.closeClicked}
									>
										Save
									</Button>
									<Button
										className="btn btn-lg"
										color="warning"
										onClick={store.closeClicked}
									>
										Close
									</Button>
								</Col>
							</Row>
						</Col>
					) : (
						<Col className="col-12">
							<Row>
								<Router>
									<Col className="col-12">
										<Nav
											id="weddingTabs"
											pills
											color="primary"
											className="nav-justified"
										>
											<NavItem>
												<NavLink
													to="#"
													className={classnames({
														active: store.activeItem === "1",
													})}
													onClick={() => {
														store.toggle("1");
													}}
												>
													<p>
														<Fa className="fa-2x" icon="briefcase" />
													</p>
													<p>General/Forms</p>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													to="#"
													className={classnames({
														active: store.activeItem === "2",
													})}
													onClick={() => {
														store.toggle("2");
													}}
												>
													<p>
														<Fa className="fa-2x" icon="list" />
													</p>
													<p>Wedding Information</p>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													to="#"
													className={classnames({
														active: store.activeItem === "3",
													})}
													onClick={() => {
														store.toggle("3");
													}}
												>
													<p>
														<Fa className="fa-2x" icon="user-circle" />
													</p>
													<p>NOIM</p>
												</NavLink>
											</NavItem>
										</Nav>
										<TabContent activeItem={store.activeItem}>
											<TabPane tabId="1">
												<Row>
													<Col lg="6" className="mt-4">
														<Card className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																General
															</CardHeader>
															<CardBody>
																<label>Archive Wedding</label>
																<SingleSelect
																	defaultValue={store.data.archivewedding}
																	onChange={store.handleSelectChange}
																	name="archivewedding"
																	options={[
																		{
																			value: true,
																			label: "Yes, archive this wedding",
																		},
																		{
																			value: false,
																			label: "No, do not archive this wedding",
																		},
																	]}
																/>
															</CardBody>
														</Card>
													</Col>
													<Col lg="6" className="mt-4">
														<Card id="formCard" className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																Forms
															</CardHeader>
															<CardBody>
																<SingleSelect
																	defaultValue={store.currentUser}
																	onChange={store.handleSelectChangeNames}
																	name="names"
																	options={[
																		{
																			value: store.names[0].id,
																			label: `${store.names[0].first_name} ${store.names[0].last_name}`,
																		},
																		{
																			value: store.names[1].id,
																			label: `${store.names[1].first_name} ${store.names[1].last_name}`,
																		},
																	]}
																/>
																<a
																	target="_target"
																	href={store.data.noimLink}
																	className="btn btn-block btn-info mb-2"
																>
																	NOIM
																</a>
																<a
																	target="_target"
																	href={store.data.decCertLink}
																	className="btn btn-block btn-info mb-2"
																>
																	Dec/Cert Combo
																</a>
																{store.data.form15.length === 0 ? (
																	<button
																		disabled
																		className="btn btn-block btn-info mb-2"
																	>
																		Marriage Certificate (Form 15)
																	</button>
																) : (
																	<a
																		target="_target"
																		href={store.data.form15Link}
																		className="btn btn-block btn-info mb-2"
																	>
																		Marriage Certificate (Form 15)
																	</a>
																)}
																<label className="mt-3">
																	CMC Registration Number
																</label>
																<Row>
																	<Col xs="6">
																		<InputField
																			name="form15"
																			value={store.data.form15}
																			onChange={store.handleInputChange}
																			disabled={false}
																		/>
																	</Col>
																	<Col xs="6">
																		<Button
																			id="saveWeddingButton"
																			disabled={!store.dirty}
																			className="btn btn-block"
																			color="success"
																			onClick={store.saveClicked}
																		>
																			{store.saveButtonText}
																		</Button>
																	</Col>
																</Row>
																<a
																	target="_target"
																	href={store.data.confLodge}
																	className="btn btn-block btn-warning mb-2"
																>
																	Fiance Visa Letter
																</a>
																<a
																	target="_target"
																	href={store.data.vows}
																	className="btn btn-block btn-warning mb-2"
																>
																	Legal Vows PMO
																</a>
																<a
																	target="_target"
																	href={store.data.orderOfCeremony}
																	className="btn btn-block btn-warning mb-2"
																>
																	Order of Ceremony
																</a>
															</CardBody>
														</Card>
													</Col>
												</Row>
												<Row>
													<Col lg="6" className="mt-4">
														<Card className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																Public Link
															</CardHeader>
															<CardBody style={{ fontSize: "14px" }}>
																<p>
																	This link gives the public access to enter
																	data into this wedding.
																</p>
																<p>
																	It is intended to optionally send to the
																	couple before your initial meeting with them.
																</p>
																<p>
																	When the link is locked, the public will no
																	longer be able to change the wedding.
																</p>
																<p>
																	This should be treated with care and would
																	normally only be unlocked for the initial data
																	entry by your couple.
																</p>
																<p></p>
																<h5>
																	Public Link:{" "}
																	<a
																		href={store.data.public_link}
																		target="_target"
																	>
																		{store.data.public_link}
																	</a>
																</h5>
																<p></p>
																<label>Locked</label>
																<SingleSelect
																	defaultValue={store.data.locked}
																	onChange={store.handleSelectChange}
																	name="locked"
																	options={[
																		{
																			value: "locked",
																			label: "Locked",
																		},
																		{
																			value: "unlocked",
																			label: "Unlocked",
																		},
																	]}
																/>
															</CardBody>
														</Card>
													</Col>
													<Col lg="6" className="mt-4">
														<Card className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																Notes
															</CardHeader>
															<CardBody>
																<textarea
																	style={{ width: "100%", fontSize: "18px" }}
																	name="notes"
																	type="textarea"
																	rows="10"
																	value={store.data.notes}
																	onChange={store.handleTextArea}
																/>
															</CardBody>
														</Card>
													</Col>
												</Row>
												<Row>
													<Col className="mt-4">
														<Card className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																Wedding Files
															</CardHeader>
															<CardBody>
																{store.mode !== "N" ? (
																	<div>
																		<Row className="text-center">
																			<Col>
																				<h6>
																					Please note, there is a 50MB limit per
																					file
																				</h6>
																			</Col>
																		</Row>
																		<Row className="text-center">
																			<Col>
																				<input
																					type="file"
																					onChange={store.fileUpload}
																				/>
																			</Col>
																		</Row>
																		{files}
																	</div>
																) : (
																	<Row>
																		<Col className="text-center">
																			<h4>
																				Please create the wedding before
																				uploading files.
																			</h4>
																		</Col>
																	</Row>
																)}
															</CardBody>
														</Card>
													</Col>
												</Row>
											</TabPane>
											<TabPane tabId="2">
												<Row>
													<Col className="mt-4">
														<Card className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																Event Details
															</CardHeader>
															<CardBody>
																<label>Marriage Date</label>
																<Row>
																	<Col className="col-12 col-lg-4">
																		<SingleSelect
																			defaultValue={store.data.marriagedate_day}
																			onChange={store.handleSelectChange}
																			name="marriagedate_day"
																			options={dateDays}
																		/>
																	</Col>
																	<Col className="col-12 col-lg-4">
																		<SingleSelect
																			defaultValue={
																				store.data.marriagedate_month
																			}
																			onChange={store.handleSelectChange}
																			name="marriagedate_month"
																			options={dateMonths}
																		/>
																	</Col>
																	<Col className="col-12 col-lg-4">
																		<SingleSelect
																			defaultValue={
																				store.data.marriagedate_year
																			}
																			onChange={store.handleSelectChange}
																			className="form-control"
																			name="marriagedate_year"
																			options={dateYears}
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col className="text-center">
																		<input
																			value={store.marriagedate_weekday}
																			onChange={store.handleInputChange}
																			className="form-control form-control-lg text-center"
																			disabled
																		/>
																	</Col>
																</Row>
																<label>Marriage Location</label>
																<InputField
																	name="marriageat1"
																	value={store.data.marriageat1}
																	onChange={store.handleInputChange}
																	disabled={false}
																/>
																<label>Marriage State (Australia)</label>
																<SingleSelect
																	defaultValue={
																		store.data.marriagestate_selection
																	}
																	onChange={store.handleSelectChange}
																	name="marriagestate_selection"
																	options={australianStates}
																/>
																<label>Form 15 Wedding Location</label>
																<InputField
																	name="form_address"
																	value={store.data.form_address}
																	onChange={store.handleInputChange}
																	disabled={false}
																/>
															</CardBody>
														</Card>
													</Col>
												</Row>
												<Row>
													<Col className="mt-4">
														<Card className="h-100">
															<CardHeader
																color="elegant-color-dark"
																tag="h3"
																className="text-center"
															>
																Dec/Cert
															</CardHeader>
															<CardBody>
																<Row className="mt-3">
																	<Col className="col-6 text-center">
																		<h3 className="text-center">
																			<Badge
																				color="rgba-black-strong"
																				style={{ width: "100%" }}
																			>
																				Party 1
																			</Badge>
																		</h3>
																	</Col>
																	<Col className="col-6 text-center">
																		<h3 className="text-center">
																			<Badge
																				color="rgba-black-strong"
																				style={{ width: "100%" }}
																			>
																				Party 2
																			</Badge>
																		</h3>
																	</Col>
																</Row>
																<Row>
																	<Col>
																		<label>Declaration Place</label>
																	</Col>
																	<Col>
																		<label>Declaration Place</label>
																	</Col>
																</Row>
																<Row>
																	<Col className="col-6">
																		<InputField
																			name="groom_decplace"
																			value={store.data.groom_decplace}
																			onChange={store.handleInputChange}
																			disabled={false}
																		/>
																	</Col>
																	<Col className="col-6">
																		<InputField
																			name="bride_decplace"
																			value={store.data.bride_decplace}
																			onChange={store.handleInputChange}
																			disabled={false}
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col>
																		<label>Declaration Date</label>
																	</Col>
																	<Col>
																		<label>Declaration Date</label>
																	</Col>
																</Row>
																<Row>
																	<Col className="col-6">
																		<SingleSelect
																			defaultValue={
																				store.data.groom_decdate_day
																			}
																			onChange={store.handleSelectChange}
																			name="groom_decdate_day"
																			options={dateDays}
																		/>
																	</Col>
																	<Col className="col-6">
																		<SingleSelect
																			defaultValue={
																				store.data.bride_decdate_day
																			}
																			onChange={store.handleSelectChange}
																			name="bride_decdate_day"
																			options={dateDays}
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col className="col-6">
																		<SingleSelect
																			defaultValue={
																				store.data.groom_decdate_month
																			}
																			onChange={store.handleSelectChange}
																			name="groom_decdate_month"
																			options={dateMonths}
																		/>
																	</Col>
																	<Col className="col-6">
																		<SingleSelect
																			defaultValue={
																				store.data.bride_decdate_month
																			}
																			onChange={store.handleSelectChange}
																			name="bride_decdate_month"
																			options={dateMonths}
																		/>
																	</Col>
																</Row>
																<Row>
																	<Col className="col-6">
																		<SingleSelect
																			defaultValue={
																				store.data.groom_decdate_year
																			}
																			onChange={store.handleSelectChange}
																			name="groom_decdate_year"
																			options={dateYears}
																		/>
																	</Col>
																	<Col className="col-6">
																		<SingleSelect
																			defaultValue={
																				store.data.bride_decdate_year
																			}
																			onChange={store.handleSelectChange}
																			name="bride_decdate_year"
																			options={dateYears}
																		/>
																	</Col>
																</Row>
															</CardBody>
														</Card>
													</Col>
												</Row>
											</TabPane>
											<TabPane tabId="3">
												<NOIM />
											</TabPane>
										</TabContent>
									</Col>
								</Router>
							</Row>
							<SaveArea />
						</Col>
					)}
					<Modal isOpen={store.modal} toggle={store.toggleModal} size="lg">
						<ModalHeader toggle={store.toggleModal}>
							Unsaved Wedding Changes
						</ModalHeader>
						<ModalBody>
							<p>
								You have made changes to this wedding that have not been saved
							</p>
							<p>
								Would you like to continue changing the wedding or delete the
								changes and close the wedding?
							</p>
						</ModalBody>
						<ModalFooter>
							<Button color="success" onClick={store.toggleModal}>
								Continue
							</Button>
							<Button color="danger" onClick={store.closeAll}>
								Delete Changes
							</Button>
						</ModalFooter>
					</Modal>
					<Modal
						isOpen={store.deleteModal}
						toggle={store.toggleDeleteModal}
						size="lg"
					>
						<ModalHeader toggle={store.toggleDeleteModal}>
							Delete File
						</ModalHeader>
						<ModalBody>
							<p>Are you sure you would like to delete this file?</p>
							<p>
								Once the file has been deleted from the server, it cannot be
								brought back.
							</p>
						</ModalBody>
						<ModalFooter className="justify-content-center">
							<Button color="danger" onClick={store.deleteFileConfirmed}>
								Delete File
							</Button>
							<Button color="success" onClick={store.toggleDeleteModal}>
								Close
							</Button>
						</ModalFooter>
					</Modal>
				</Row>
			);
		}
	}
);

var SaveArea = observer(
	class SaveArea extends React.Component {
		render() {
			var secondaryButton;
			if (store.mode === "E" || store.mode === "N") {
				secondaryButton = (
					<Button
						id="closeWeddingButton"
						className="btn btn-lg"
						color="warning"
						onClick={store.closeClicked}
					>
						Close
					</Button>
				);
			} else if (store.mode === "Z") {
				secondaryButton = <div></div>;
			} else if (store.mode === "P") {
				secondaryButton = (
					<Button
						disabled={store.create_wedding === false}
						className="btn btn-lg"
						color="warning"
						onClick={store.saveAndPrint}
					>
						Print NOIM
					</Button>
				);
			}
			return (
				<Row className="pt-3 my-3">
					<Col></Col>
					<Col className="col-12 text-center">
						{store.mode === "P" && store.publicDirty === false ? (
							<MDBTooltip placement="top" tag="div">
								<Button
									id="saveWeddingButton"
									className="btn btn-lg"
									color="success"
									onClick={store.saveClicked}
								>
									{store.saveButtonText}
								</Button>
								<div>
									Please enter names, date of birth, place of birth, phone
									numbers, and email addresses for both parties
								</div>
							</MDBTooltip>
						) : (
							<Button
								id="saveWeddingButton"
								disabled={!store.dirty}
								className="btn btn-lg"
								color="success"
								onClick={store.saveClicked}
							>
								{store.saveButtonText}
							</Button>
						)}
						{secondaryButton}
					</Col>
					<Col></Col>
					<Modal
						className="form-cascading"
						isOpen={store.finalModal}
						toggle={store.toggleFinalModal}
						centered
					>
						<ModalBody className="mt-2">
							<h1 className="text-center">
								<strong>NOIM Information Submitted</strong>
							</h1>
							<p>
								Please follow the instructions sent to your email address for
								lodgement confirmation.
							</p>
						</ModalBody>
						<ModalFooter className="justify-content-center">
							<button
								id="loginFormButton"
								className="btn btn-lg btn-success mb-2"
								onClick={store.toggleFinalModal}
							>
								Close
							</button>
						</ModalFooter>
					</Modal>
				</Row>
			);
		}
	}
);

class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		this.props.onChange(event.target.name, event.target.value);
	}

	render() {
		return (
			<input
				name={this.props.name}
				type={
					this.props.type && this.props.type === "number" ? "number" : "text"
				}
				className="form-control form-control-lg"
				value={this.props.value}
				onChange={this.props.onChange}
				disabled={this.props.disabled}
				placeholder={this.props.placeholder}
			/>
		);
	}
}

var countryList = [
	{
		value: "Unknown",
		label: "Unknown/Invalid Country",
	},
	{
		value: "Afghanistan",
		label: "Afghanistan",
	},
	{
		value: "Albania",
		label: "Albania",
	},
	{
		value: "Algeria",
		label: "Algeria",
	},
	{
		value: "Andorra",
		label: "Andorra",
	},
	{
		value: "Angola",
		label: "Angola",
	},
	{
		value: "Antigua & Deps",
		label: "Antigua & Deps",
	},
	{
		value: "Argentina",
		label: "Argentina",
	},
	{
		value: "Armenia",
		label: "Armenia",
	},
	{
		value: "",
		label: "Australia",
	},
	{
		value: "Austria",
		label: "Austria",
	},
	{
		value: "Azerbaijan",
		label: "Azerbaijan",
	},
	{
		value: "Bahamas",
		label: "Bahamas",
	},
	{
		value: "Bahrain",
		label: "Bahrain",
	},
	{
		value: "Bangladesh",
		label: "Bangladesh",
	},
	{
		value: "Barbados",
		label: "Barbados",
	},
	{
		value: "Belarus",
		label: "Belarus",
	},
	{
		value: "Belgium",
		label: "Belgium",
	},
	{
		value: "Belize",
		label: "Belize",
	},
	{
		value: "Benin",
		label: "Benin",
	},
	{
		value: "Bhutan",
		label: "Bhutan",
	},
	{
		value: "Bolivia",
		label: "Bolivia",
	},
	{
		value: "Bosnia Herzegovina",
		label: "Bosnia Herzegovina",
	},
	{
		value: "Botswana",
		label: "Botswana",
	},
	{
		value: "Brazil",
		label: "Brazil",
	},
	{
		value: "Brunei",
		label: "Brunei",
	},
	{
		value: "Bulgaria",
		label: "Bulgaria",
	},
	{
		value: "Burkina",
		label: "Burkina",
	},
	{
		value: "Burundi",
		label: "Burundi",
	},
	{
		value: "Cambodia",
		label: "Cambodia",
	},
	{
		value: "Cameroon",
		label: "Cameroon",
	},
	{
		value: "Canada",
		label: "Canada",
	},
	{
		value: "Cape Verde",
		label: "Cape Verde",
	},
	{
		value: "Central African Rep",
		label: "Central African Rep",
	},
	{
		value: "Chad",
		label: "Chad",
	},
	{
		value: "Chile",
		label: "Chile",
	},
	{
		value: "China",
		label: "China",
	},
	{
		value: "Colombia",
		label: "Colombia",
	},
	{
		value: "Comoros",
		label: "Comoros",
	},
	{
		value: "Congo",
		label: "Congo",
	},
	{
		value: "Costa Rica",
		label: "Costa Rica",
	},
	{
		value: "Croatia",
		label: "Croatia",
	},
	{
		value: "Cuba",
		label: "Cuba",
	},
	{
		value: "Cyprus",
		label: "Cyprus",
	},
	{
		value: "Czech Republic",
		label: "Czech Republic",
	},
	{
		value: "Denmark",
		label: "Denmark",
	},
	{
		value: "Djibouti",
		label: "Djibouti",
	},
	{
		value: "Dominica",
		label: "Dominica",
	},
	{
		value: "Dominican Republic",
		label: "Dominican Republic",
	},
	{
		value: "East Timor",
		label: "East Timor",
	},
	{
		value: "Ecuador",
		label: "Ecuador",
	},
	{
		value: "Egypt",
		label: "Egypt",
	},
	{
		value: "El Salvador",
		label: "El Salvador",
	},
	{
		value: "England",
		label: "England",
	},
	{
		value: "Equatorial Guinea",
		label: "Equatorial Guinea",
	},
	{
		value: "Eritrea",
		label: "Eritrea",
	},
	{
		value: "Estonia",
		label: "Estonia",
	},
	{
		value: "Ethiopia",
		label: "Ethiopia",
	},
	{
		value: "Fiji",
		label: "Fiji",
	},
	{
		value: "Finland",
		label: "Finland",
	},
	{
		value: "France",
		label: "France",
	},
	{
		value: "Gabon",
		label: "Gabon",
	},
	{
		value: "Gambia",
		label: "Gambia",
	},
	{
		value: "Georgia",
		label: "Georgia",
	},
	{
		value: "Germany",
		label: "Germany",
	},
	{
		value: "Ghana",
		label: "Ghana",
	},
	{
		value: "Greece",
		label: "Greece",
	},
	{
		value: "Grenada",
		label: "Grenada",
	},
	{
		value: "Guatemala",
		label: "Guatemala",
	},
	{
		value: "Guinea",
		label: "Guinea",
	},
	{
		value: "Guinea-Bissau",
		label: "Guinea-Bissau",
	},
	{
		value: "Guyana",
		label: "Guyana",
	},
	{
		value: "Haiti",
		label: "Haiti",
	},
	{
		value: "Honduras",
		label: "Honduras",
	},
	{
		value: "Hungary",
		label: "Hungary",
	},
	{
		value: "Iceland",
		label: "Iceland",
	},
	{
		value: "India",
		label: "India",
	},
	{
		value: "Indonesia",
		label: "Indonesia",
	},
	{
		value: "Isle of Man",
		label: "Isle of Man",
	},
	{
		value: "Iran",
		label: "Iran",
	},
	{
		value: "Iraq",
		label: "Iraq",
	},
	{
		value: "Ireland",
		label: "Ireland",
	},
	{
		value: "Israel",
		label: "Israel",
	},
	{
		value: "Italy",
		label: "Italy",
	},
	{
		value: "Ivory Coast",
		label: "Ivory Coast",
	},
	{
		value: "Jamaica",
		label: "Jamaica",
	},
	{
		value: "Japan",
		label: "Japan",
	},
	{
		value: "Jordan",
		label: "Jordan",
	},
	{
		value: "Kazakhstan",
		label: "Kazakhstan",
	},
	{
		value: "Kenya",
		label: "Kenya",
	},
	{
		value: "Kiribati",
		label: "Kiribati",
	},
	{
		value: "North Korea",
		label: "North Korea",
	},
	{
		value: "South Korea",
		label: "South Korea",
	},
	{
		value: "Kosovo",
		label: "Kosovo",
	},
	{
		value: "Kuwait",
		label: "Kuwait",
	},
	{
		value: "Kyrgyzstan",
		label: "Kyrgyzstan",
	},
	{
		value: "Laos",
		label: "Laos",
	},
	{
		value: "Latvia",
		label: "Latvia",
	},
	{
		value: "Lebanon",
		label: "Lebanon",
	},
	{
		value: "Lesotho",
		label: "Lesotho",
	},
	{
		value: "Liberia",
		label: "Liberia",
	},
	{
		value: "Libya",
		label: "Libya",
	},
	{
		value: "Liechtenstein",
		label: "Liechtenstein",
	},
	{
		value: "Lithuania",
		label: "Lithuania",
	},
	{
		value: "Luxembourg",
		label: "Luxembourg",
	},
	{
		value: "Macedonia",
		label: "Macedonia",
	},
	{
		value: "Madagascar",
		label: "Madagascar",
	},
	{
		value: "Malawi",
		label: "Malawi",
	},
	{
		value: "Malaysia",
		label: "Malaysia",
	},
	{
		value: "Maldives",
		label: "Maldives",
	},
	{
		value: "Mali",
		label: "Mali",
	},
	{
		value: "Malta",
		label: "Malta",
	},
	{
		value: "Marshall Islands",
		label: "Marshall Islands",
	},
	{
		value: "Mauritania",
		label: "Mauritania",
	},
	{
		value: "Mauritius",
		label: "Mauritius",
	},
	{
		value: "Mexico",
		label: "Mexico",
	},
	{
		value: "Micronesia",
		label: "Micronesia",
	},
	{
		value: "Moldova",
		label: "Moldova",
	},
	{
		value: "Monaco",
		label: "Monaco",
	},
	{
		value: "Mongolia",
		label: "Mongolia",
	},
	{
		value: "Montenegro",
		label: "Montenegro",
	},
	{
		value: "Morocco",
		label: "Morocco",
	},
	{
		value: "Mozambique",
		label: "Mozambique",
	},
	{
		value: "Myanmar",
		label: "Myanmar",
	},
	{
		value: "Namibia",
		label: "Namibia",
	},
	{
		value: "Nauru",
		label: "Nauru",
	},
	{
		value: "Nepal",
		label: "Nepal",
	},
	{
		value: "Netherlands",
		label: "Netherlands",
	},
	{
		value: "New Zealand",
		label: "New Zealand",
	},
	{
		value: "Nicaragua",
		label: "Nicaragua",
	},
	{
		value: "Niger",
		label: "Niger",
	},
	{
		value: "Nigeria",
		label: "Nigeria",
	},
	{
		value: "Norway",
		label: "Norway",
	},
	{
		value: "Oman",
		label: "Oman",
	},
	{
		value: "Pakistan",
		label: "Pakistan",
	},
	{
		value: "Palau",
		label: "Palau",
	},
	{
		value: "Panama",
		label: "Panama",
	},
	{
		value: "Papua New Guinea",
		label: "Papua New Guinea",
	},
	{
		value: "Paraguay",
		label: "Paraguay",
	},
	{
		value: "Peru",
		label: "Peru",
	},
	{
		value: "Philippines",
		label: "Philippines",
	},
	{
		value: "Poland",
		label: "Poland",
	},
	{
		value: "Portugal",
		label: "Portugal",
	},
	{
		value: "Puerto Rico",
		label: "Puerto Rico",
	},
	{
		value: "Qatar",
		label: "Qatar",
	},
	{
		value: "Romania",
		label: "Romania",
	},
	{
		value: "Russian Federation",
		label: "Russian Federation",
	},
	{
		value: "Rwanda",
		label: "Rwanda",
	},
	{
		value: "Saint Vincent & the Grenadines",
		label: "Saint Vincent & the Grenadines",
	},
	{
		value: "Samoa",
		label: "Samoa",
	},
	{
		value: "San Marino",
		label: "San Marino",
	},
	{
		value: "Sao Tome & Principe",
		label: "Sao Tome & Principe",
	},
	{
		value: "Saudi Arabia",
		label: "Saudi Arabia",
	},
	{
		value: "Scotland",
		label: "Scotland",
	},
	{
		value: "Senegal",
		label: "Senegal",
	},
	{
		value: "Serbia",
		label: "Serbia",
	},
	{
		value: "Seychelles",
		label: "Seychelles",
	},
	{
		value: "Sierra Leone",
		label: "Sierra Leone",
	},
	{
		value: "Singapore",
		label: "Singapore",
	},
	{
		value: "Slovakia",
		label: "Slovakia",
	},
	{
		value: "Slovenia",
		label: "Slovenia",
	},
	{
		value: "Solomon Islands",
		label: "Solomon Islands",
	},
	{
		value: "Somalia",
		label: "Somalia",
	},
	{
		value: "South Africa",
		label: "South Africa",
	},
	{
		value: "South Sudan",
		label: "South Sudan",
	},
	{
		value: "Spain",
		label: "Spain",
	},
	{
		value: "Sri Lanka",
		label: "Sri Lanka",
	},
	{
		value: "St Kitts & Nevis",
		label: "St Kitts & Nevis",
	},
	{
		value: "St Lucia",
		label: "St Lucia",
	},
	{
		value: "Sudan",
		label: "Sudan",
	},
	{
		value: "Suriname",
		label: "Suriname",
	},
	{
		value: "Swaziland",
		label: "Swaziland",
	},
	{
		value: "Sweden",
		label: "Sweden",
	},
	{
		value: "Switzerland",
		label: "Switzerland",
	},
	{
		value: "Syria",
		label: "Syria",
	},
	{
		value: "Taiwan",
		label: "Taiwan",
	},
	{
		value: "Tajikistan",
		label: "Tajikistan",
	},
	{
		value: "Tanzania",
		label: "Tanzania",
	},
	{
		value: "Thailand",
		label: "Thailand",
	},
	{
		value: "Togo",
		label: "Togo",
	},
	{
		value: "Tonga",
		label: "Tonga",
	},
	{
		value: "Trinidad & Tobago",
		label: "Trinidad & Tobago",
	},
	{
		value: "Tunisia",
		label: "Tunisia",
	},
	{
		value: "Turkey",
		label: "Turkey",
	},
	{
		value: "Turkmenistan",
		label: "Turkmenistan",
	},
	{
		value: "Tuvalu",
		label: "Tuvalu",
	},
	{
		value: "Uganda",
		label: "Uganda",
	},
	{
		value: "Ukraine",
		label: "Ukraine",
	},
	{
		value: "United Arab Emirates",
		label: "United Arab Emirates",
	},
	{
		value: "United States",
		label: "United States",
	},
	{
		value: "Uruguay",
		label: "Uruguay",
	},
	{
		value: "Uzbekistan",
		label: "Uzbekistan",
	},
	{
		value: "Vanuatu",
		label: "Vanuatu",
	},
	{
		value: "Vatican",
		label: "Vatican",
	},
	{
		value: "Venezuela",
		label: "Venezuela",
	},
	{
		value: "Vietnam",
		label: "Vietnam",
	},
	{
		value: "Wales",
		label: "Wales",
	},
	{
		value: "Yemen",
		label: "Yemen",
	},
	{
		value: "Zambia",
		label: "Zambia",
	},
	{
		value: "Zimbabwe",
		label: "Zimbabwe",
	},
	{
		value: "Other",
		label: "Other",
	},
];

export default WeddingDataClass;
