// https://magic.reactjs.net/htmltojsx.htm

import React, { Component } from 'react'
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  toast,
  ToastContainer
}
from 'mdbreact'
import * as Sentry from '@sentry/browser'

// import $ from 'jquery'
// import 'bootstrap'

class FeedbackPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      feedback: "",
      disabled: false
    }
    this.changeFeedback = this.changeFeedback.bind(this)
    this.submitFeedback = this.submitFeedback.bind(this)
  }
  changeFeedback(event) {
    this.setState({
      feedback: event.target.value
    })
  }
  submitFeedback() {
    var _this = this
    axios.post("/member/api/feedback/create", this.state)
      .then(function(response) {
        toast.success("Feedback Submitted!", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: false,
          draggable: false,
          pauseOnHover: false
        });
        _this.setState({
          disabled: true
        })
      })
      .catch(function(error) {
        Sentry.captureException(error)
        console.log(error)
        window.location.replace("/");
      })
  }
  render() {
    return (
      <div className="text-center" style={{paddingTop: "30px"}}>
        <h1>
          Feedback
        </h1>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          draggable={false}
          pauseOnHover={false}>
        </ToastContainer>
        <Row>
          <Col className="col-1"></Col>
          <Col className="col-10 mt-4">
            <Card className="h-100">
              <CardBody>
                <p className="text-center">Please feel free to provide any feedback, questions, issues, concerns, ideas, or critisisms!</p>
                <textarea className="form-control" rows="8" value={this.state.feedback} onChange={this.changeFeedback} />
                <Row>
                  <Col>
                    <button
                      disabled={this.state.disabled}
                      onClick={this.submitFeedback}
                      className="btn btn-success mt-3 btn-lg btn-block">Submit</button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-1"></Col>
        </Row>
      </div>
    )
  }
}

export default FeedbackPage
