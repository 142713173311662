// https://magic.reactjs.net/htmltojsx.htm

import React, { Component } from 'react'
import {
	Spinner,
	Row,
	Col,
	ToastContainer,
	toast,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Progress
}
	from 'mdbreact'
import axios from "axios"
import SingleSelect from '../general/SingleSelect'
import * as Sentry from '@sentry/browser'
import "./Account.css"

class AccountPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loaded: false,
			dirty: false,
			modalCelebrante: false,
			modalAfcc: false,
			modalResetPassword: false,
			currentPassword: "",
			newPassword: "",
			newPasswordConfirmation: "",
			invalidPasswordClass: "",
			disablePasswordButton: true,
			data: { names: [] },
			first_name: "Loading...",
			last_name: "Loading...",
			celebrant_number: "Loading..."
		}
		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleInputChangeNames = this.handleInputChangeNames.bind(this)
		this.handleInputChangePassword = this.handleInputChangePassword.bind(this)
		this.handleSelectChange = this.handleSelectChange.bind(this)
		this.updateClicked = this.updateClicked.bind(this)
		this.checkData = this.checkData.bind(this)
		this.togglePassword = this.togglePassword.bind(this)
		// this.toggleCelebrante = this.toggleCelebrante.bind(this)
		// this.yesCelebrante = this.yesCelebrante.bind(this)
		// this.noCelebrante = this.noCelebrante.bind(this)
		// this.yesAfcc = this.yesAfcc.bind(this)
		// this.noAfcc = this.noAfcc.bind(this)
		this.submitPasswordChange = this.submitPasswordChange.bind(this)
	}
	componentDidMount() {
		var _this = this
		axios.get('/member/api/user/get-settings')
			.then(function (response) {
				var responseData = response.data[0];
				console.log(responseData)
				var activatedText
				var spaceFree = response.data[0].spaceFree
				var spaceTotal = response.data[0].spaceTotal
				if (responseData.status === "A") {
					var activatedDate;
					if (responseData.amt_activated === 99 || responseData.amt_activated === 77) {
						activatedDate = new Date(responseData.activated_date);
						activatedDate.setDate(activatedDate.getDate() + 365);
						activatedText = "Active until " + activatedDate.toDateString();
					}
					else if (responseData.amt_activated === 49) {
						activatedDate = new Date(responseData.activated_date);
						activatedDate.setDate(activatedDate.getDate() + 183);
						activatedText = "Active until " + activatedDate.toDateString();
					}
				}
				else if (responseData.status === "I") {
					activatedText = "Inactive";
				}
				else if (responseData.status === "T") {
					activatedText = "Test Status";
				}
				var printerOptions = []
				for (var x = 0; x < response.data[0].printerData.length; x++) {
					printerOptions.push({
						value: response.data[0].printerData[x].id.toString(),
						label: response.data[0].printerData[x].printer_name
					})
				}
				var data = response.data[0]
				delete data.spaceFree
				delete data.spaceTotal
				// data.status = activatedText
				data.activatedText = activatedText
				data.names = response.data[0].names
				var dataCopy = JSON.parse(JSON.stringify(data))
				_this.setState({
					loaded: true,
					data: data,
					originalData: dataCopy,
					printerOptions: printerOptions,
					spaceFree: spaceFree,
					spaceTotal: spaceTotal,
					first_name: responseData.names[0].first_name,
					last_name: responseData.names[0].last_name,
					celebrant_number: responseData.names[0].celebrant_number,
					currentUser: data.names[0].id
				})
				// if (responseData.status === "I") {
				//   if (responseData.celebrante_confirmed === "Y" && responseData.celebrante === "Y") {
				//     $("#celebrantePayment").removeClass('hide');
				//   }
				//   else {
				//     $("#normalPayment").removeClass('hide');
				//   }
				// }
			})
			.catch(function (error) {
				Sentry.captureException(error)
				console.log(error);
				window.location.replace("/");
			});
	}
	yesCelebrante() {
		var data = this.state.data
		data.celebrante = "Y"
		this.setState({
			modalCelebrante: false,
			data: data
		})
	}
	noCelebrante() {
		var data = this.state.data
		data.celebrante = "N"
		this.setState({
			modalCelebrante: false,
			data: data
		}, () => this.checkData())
	}
	// yesAfcc() {
	//   var data = this.state.data
	//   data.afcc = "Y"
	//   this.setState({
	//     modalAfcc: false,
	//     data: data
	//   })
	// }
	// noAfcc() {
	//   var data = this.state.data
	//   data.afcc = "N"
	//   this.setState({
	//     modalAfcc: false,
	//     data: data
	//   }, () => this.checkData())
	// }
	// toggleAfcc() {
	//   this.setState({
	//     modalAfcc: !this.state.modalCelebrante
	//   })
	// }
	// toggleCelebrante() {
	//   this.setState({
	//     modalCelebrante: !this.state.modalCelebrante
	//   })
	// }
	togglePassword() {
		var currentPassword = this.state.currentPassword
		var newPassword = this.state.newPassword
		var newPasswordConfirmation = this.state.newPasswordConfirmation
		if (this.state.modalResetPassword === false) {
			//This means we are about to open the modal
			currentPassword = ""
			newPassword = ""
			newPasswordConfirmation = ""
		}
		this.setState({
			currentPassword: currentPassword,
			newPassword: newPassword,
			newPasswordConfirmation: newPasswordConfirmation,
			modalResetPassword: !this.state.modalResetPassword,
			disablePasswordButton: true
		})
	}
	checkData() {
		var dataCopy = JSON.stringify(this.state.data)
		var originalDataCopy = JSON.stringify(this.state.originalData)
		if (dataCopy === originalDataCopy) {
			this.setState({
				dirty: false
			})
		}
		else {
			this.setState({
				dirty: true
			})
		}
	}
	submitPasswordChange() {
		var _this = this
		axios.post("/member/api/reset-password", {
			currentPassword: this.state.currentPassword,
			newPassword: this.state.newPassword,
			newPasswordConfirmation: this.state.newPasswordConfirmation
		})
			.then(function (response) {
				if (response.status === 200) {
					toast.success("Password change successful!", {
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: false,
						draggable: false,
						pauseOnHover: false
					});
					_this.togglePassword()
				}
				else {
					toast.error("ERROR! Please try again.", {
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: false,
						draggable: false,
						pauseOnHover: false
					});
				}
			})
			.catch(function (error) {
				Sentry.captureException(error)
				console.log(error)
				toast.error("ERROR! Incorrect password.", {
					position: "top-right",
					autoClose: 3000,
					closeButton: false,
					hideProgressBar: false,
					draggable: false,
					pauseOnHover: false
				});
			})
	}
	updateClicked(event) {
		event.preventDefault()
		var _this = this
		var updateData = this.state.data
		delete updateData.printerData
		delete updateData.activatedText
		axios.post("/member/api/user/update-settings", updateData)
			.then(function (response) {
				if (response.status === 200) {
					toast.success("Settings Saved!", {
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: false,
						draggable: false,
						pauseOnHover: false
					});
					_this.setState({
						originalData: JSON.parse(JSON.stringify(_this.state.data)),
						dirty: false,
						saveButtonText: "SAVE"
					})
				}
				else {
					toast.error("ERROR! Please try again.", {
						position: "top-right",
						autoClose: 3000,
						closeButton: false,
						hideProgressBar: false,
						draggable: false,
						pauseOnHover: false
					});
				}
			})
			.catch(function (error) {
				Sentry.captureException(error)
				console.log(error)
				toast.error("ERROR! Please try again.", {
					position: "top-right",
					autoClose: 3000,
					closeButton: false,
					hideProgressBar: false,
					draggable: false,
					pauseOnHover: false
				});
			})
	}
	handleSelectChange(name, value) {
		var _this = this
		var newData = this.state.data
		var modalCelebrante = false
		var modalAfcc = false
		var currentUser = this.state.currentUser
		var first_name = this.state.first_name
		var last_name = this.state.last_name
		var celebrant_number = this.state.celebrant_number
		if (newData.afcc === "N" && name === "afcc" && value.value === "Y") {
			modalAfcc = true
		}
		if (newData.celebrante === "N" && name === "celebrante" && value.value === "Y") {
			modalCelebrante = true
		}
		if (name === "names") {
			var newNames = newData.names.map(function (name) {
				if (name.id === value.value) {
					first_name = name.first_name
					last_name = name.last_name
					celebrant_number = name.celebrant_number
				}
				return name
			})
			newData.names = newNames
			currentUser = value.value
		} else {
			newData[name] = value.value
		}
		this.setState({
			data: newData,
			modalCelebrante: modalCelebrante,
			modalAfcc: modalAfcc,
			currentUser: currentUser,
			first_name: first_name,
			last_name: last_name,
			celebrant_number: celebrant_number
		}, _this.checkData())
	}
	handleInputChange(event) {
		var _this = this
		var dataName = event.target.name
		var dataValue = event.target.value
		var newData = this.state.data
		if (dataName === "email") {
			dataValue = dataValue.toLowerCase()
		}
		newData[dataName] = dataValue
		this.setState({
			data: newData
		}, () => _this.checkData())
	}
	handleInputChangeNames(event) {
		var _this = this
		var dataName = event.target.name
		var dataValue = event.target.value
		var newData = this.state.data
		newData.names.map(function (name) {
			if (name.id === _this.state.currentUser) {
				name[dataName] = dataValue
			}
			return name
		})
		// newNames.names[this.state.currentUser][dataName] = dataValue
		this.setState({
			[dataName]: dataValue,
			data: newData
		}, () => _this.checkData())
	}
	handleInputChangePassword(event) {
		var dataName = event.target.name
		var dataValue = event.target.value
		var newInvalidClass = this.state.invalidPasswordClass
		if (dataName === "newPassword") {
			if (dataValue !== this.state.newPasswordConfirmation) {
				newInvalidClass = " is-invalid"
			}
			else {
				newInvalidClass = ""
			}
		}
		else if (dataName === "newPasswordConfirmation") {
			if (this.state.newPassword !== dataValue) {
				newInvalidClass = " is-invalid"
			}
			else {
				newInvalidClass = ""
			}
		}
		this.setState({
			[dataName]: dataValue,
			invalidPasswordClass: newInvalidClass
		}, () => {
			var newDisablePasswordButton = this.state.disablePasswordButton
			if (this.state.invalidPasswordClass.length === 0 && this.state.currentPassword.length > 0 && this.state.newPassword.length > 0) {
				newDisablePasswordButton = false
			}
			else {
				newDisablePasswordButton = true
			}
			this.setState({
				disablePasswordButton: newDisablePasswordButton
			})
		})
	}
	render() {
		var nameSelectItems = this.state.data.names.map(function (item) {
			return { value: item.id, label: `${item.first_name} ${item.last_name}` }
		})
		var celebrante = <div id="celebrantePayment" className="row mt-5">
			<div className="col-12 text-center">
				<p>6 Month Licence: $38.50 AUD</p>
				<p>12 Month Licence: $77 AUD</p>
				<p></p>
				<p>Please make the payment above to:</p>
				<p></p>
				<p>BSB: 066 534</p>
				<p></p>
				<p>Account: 1011 6418</p>
				<p></p>
				<p>Name: Nick Brekalo</p>
				<p></p>
				<p>Please add your email address (as much of it as you can fit) so I can activate your account as quickly as possible!</p>
			</div>
		</div>
		var nonCelebrante = <div id="normalPayment" className="row mt-5">
			<div className="col-12 text-center">
				<p>6 Month Licence: $49.50 AUD</p>
				<p>12 Month Licence: $99 AUD</p>
				<p></p>
				<p>Please make the payment above to:</p>
				<p></p>
				<p>BSB: 066 534</p>
				<p></p>
				<p>Account: 1011 6418</p>
				<p></p>
				<p>Name: Nick Brekalo</p>
				<p></p>
				<p>Please add your email address (as much of it as you can fit) so I can activate your account as quickly as possible!</p>
			</div>
		</div>
		var text
		if (this.state.data && this.state.data.status === "I") {
			if (this.state.data.celebrante === "Y") {
				text = celebrante
			}
			else {
				text = nonCelebrante
			}
		}
		return (
			<div style={{ paddingTop: "30px" }}>
				<h1 className="text-center mb-5">
					Account
        </h1>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					closeButton={false}
					hideProgressBar
					newestOnTop={false}
					rtl={false}
					draggable={false}
					pauseOnHover={false} >
				</ToastContainer>
				{(this.state.loaded ?
					<form id="userSettingsForm">
						<div id="settings-table" className="text-left">
							<Row className="justify-content-center mb-5">
								<Col className="col-10">
									<Row>
										<Col className="text-center">
											<h3>File Storage</h3>
										</Col>
									</Row>
									<Row>
										<Col>
											<Progress value={((this.state.spaceTotal - this.state.spaceFree) / this.state.spaceTotal) * 100} color="success" animated></Progress>
										</Col>
									</Row>
									<Row>
										<Col className="text-center">
											{(this.state.spaceTotal - this.state.spaceFree)}GB / {this.state.spaceTotal}GB
                    </Col>
									</Row>
								</Col>
							</Row>
							<div className="row">
								<div className="col-2"></div>
								<div className="col mt-0 mb-3">
									<button id="resetPasswordModalButton" onClick={this.togglePassword} type="button" className="btn btn-info btn-block">Change Password</button>
								</div>
								<div className="col-2"></div>
							</div>
							<div className="row">
								<div className="col-1"></div>
								<div className="col-10">
									<div className="row">
										<div className="col-12 col-md-6 card-block">
											<div className="card h-100">
												<div className="card-body">
													<div className="row">
														<div className="col-12">
															<div className="row">
																<div className="col-12">
																	<label htmlFor="email">
																		Email (Lowercase)
        						                                    </label>
																</div>
																<div className="col-12">
																	<input
																		name="email"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.data.email}
																		onChange={this.handleInputChange}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12 mt-5 text-center">
																	<label>Users</label>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<SingleSelect
																		defaultValue={this.state.currentUser}
																		onChange={this.handleSelectChange}
																		name="names"
																		options={nameSelectItems} />
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label htmlFor="first_name">
																		First Name
                                              						</label>
																</div>
																<div className="col-12">
																	<input
																		name="first_name"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.first_name}
																		onChange={this.handleInputChangeNames}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label htmlFor="last_name">
																		Last Name
                                              						</label>
																</div>
																<div className="col-12">
																	<input
																		name="last_name"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.last_name}
																		onChange={this.handleInputChangeNames}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label>
																		Celebrant Number
                                                  </label>
																</div>
																<div className="col-12">
																	<input
																		name="celebrant_number"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.celebrant_number}
																		onChange={this.handleInputChangeNames}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-6 card-block">
											<div className="card h-100">
												<div className="card-body">
													<div className="row">
														<div className="col-12">
															<div className="row">
																<div className="col-12">
																	<label>
																		Address 1
                                                  </label>
																</div>
																<div className="col-12">
																	<input
																		name="address_1"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.data.address_1}
																		onChange={this.handleInputChange}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label>
																		Address 2
                                                  </label>
																</div>
																<div className="col-12">
																	<input
																		name="address_2"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.data.address_2}
																		onChange={this.handleInputChange}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label>
																		State
                                                  </label>
																</div>
																<div className="col-12">
																	<input
																		name="state"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.data.state}
																		onChange={this.handleInputChange}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label>Default Printer</label>
																</div>
																<div className="col-12">
																	<SingleSelect
																		defaultValue={this.state.data.printer_id}
																		onChange={this.handleSelectChange}
																		name="printer_id"
																		options={this.state.printerOptions} />
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label htmlFor="witness_qualification">
																		Witness Qualification
                                              						</label>
																</div>
																<div className="col-12">
																	<input
																		name="witness_qualification"
																		type="text"
																		className="form-control form-control-lg"
																		value={this.state.data.witness_qualification}
																		onChange={this.handleInputChange}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<label>
																		Apply Qual to NOIM?
                                                  </label>
																</div>
																<div className="col-12">
																	<SingleSelect
																		defaultValue={this.state.data.apply_qualification_to_noim}
																		onChange={this.handleSelectChange}
																		name="apply_qualification_to_noim"
																		options={[
																			{
																				value: false,
																				label: "No"
																			},
																			{
																				value: true,
																				label: "Yes"
																			}
																		]} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-2"></div>
										<div className="col mt-3">
											<button onClick={this.updateClicked} disabled={!this.state.dirty} id="updateSettingsButton" type="submit" className="btn btn-success btn-block">Update Settings</button>
										</div>
										<div className="col-2"></div>
									</div>
								</div>
								<div className="col-1"></div>
							</div>
						</div>
					</form>
					:
					<div>
						<Row>
							<Col className="col-12 text-center">
								<Spinner blue big />
							</Col>
						</Row>
					</div>
				)}
				{text}
				<Modal isOpen={this.state.modalResetPassword} size="lg">
					<ModalHeader>Password Reset</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-12">
								<label>
									Current Password
                    </label>
							</div>
							<div className="col-12">
								<input
									name="currentPassword"
									type="password"
									className="form-control form-control-lg"
									value={this.state.currentPassword}
									onChange={this.handleInputChangePassword}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<label>
									New Password
                    </label>
							</div>
							<div className="col-12">
								<input
									name="newPassword"
									type="password"
									className={"form-control form-control-lg" + this.state.invalidPasswordClass}
									value={this.state.newPassword}
									onChange={this.handleInputChangePassword}
								/>
								<div className="invalid-feedback">The passwords are not a match</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<label>
									New Password (Confirmation)
                    </label>
							</div>
							<div className="col-12">
								<input
									name="newPasswordConfirmation"
									type="password"
									className={"form-control form-control-lg" + this.state.invalidPasswordClass}
									value={this.state.newPasswordConfirmation}
									onChange={this.handleInputChangePassword}
								/>
								<div className="invalid-feedback">The passwords are not a match</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter className="flex-center">
						<button id="resetPasswordFormButton" onClick={this.submitPasswordChange} disabled={this.state.disablePasswordButton} className="btn btn-success">Submit</button>
						<button onClick={this.togglePassword} className="btn btn-danger waves-effect">Cancel</button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

export default AccountPage
