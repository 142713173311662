import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import WeddingsPage from './Weddings.jsx'
import AccountPage from './Account.jsx'
import PrintersPage from './Printers.jsx'
import FeedbackPage from './Feedback.jsx'
import AdminPage from './Admin.jsx'
import ManagePage from './Manage.jsx'
import axios from "axios"
import * as Sentry from '@sentry/browser'
import { Row, Col, NavLink, Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem } from 'mdbreact'
import "../public/LandingPage.css"

class AppMemberPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			collapse: false,
			isWideEnough: false,
			manager: false
		}
		this.onClick = this.onClick.bind(this)
		this.onItemClick = this.onItemClick.bind(this)
	}
	onClick() {
		this.setState({
			collapse: !this.state.collapse,
		})
	}
	onItemClick() {
		var _this = this
		if (this.state.collapse === true) {
			_this.setState({
				collapse: false,
			})
		}
	}
	componentDidMount() {
		var _this = this
		axios.get("/member/api/users/get-manager")
			.then(function (response) {
				if (response.data[0].manager === "E" || response.data[0].manager === "A") {
					_this.setState({
						manager: true
					})
				}
				else {
					_this.setState({
						manager: false
					})
				}
			})
			.catch(function (error) {
				Sentry.captureException(error)
				window.location.replace("/");
			})
	}
	render() {
		return (
			<div className="container col-xs-12">
				<Navbar color="elegant-color" dark expand="lg" scrolling fixed="top">
					<NavbarBrand>
						<div>NoticeofIntendedMarriage.online</div>
					</NavbarBrand>
					{!this.state.isWideEnough && <NavbarToggler onClick={this.onClick} />}
					<Collapse isOpen={this.state.collapse} navbar>
						<NavbarNav right>
							<NavItem>
								<NavLink id="linkWeddings" onClick={this.onItemClick} to="/member/weddings">Weddings</NavLink>
							</NavItem>
							<NavItem>
								<NavLink id="linkAccount" onClick={this.onItemClick} to="/member/account">Account</NavLink>
							</NavItem>
							<NavItem>
								<NavLink id="linkPrinters" onClick={this.onItemClick} to="/member/printers">Printers</NavLink>
							</NavItem>
							<NavItem>
								<NavLink id="linkFeedback" onClick={this.onItemClick} to="/member/feedback">Feedback</NavLink>
							</NavItem>
							<NavItem>
								<NavLink id="linkAdmin" onClick={this.onItemClick} to="/member/admin">Admin</NavLink>
							</NavItem>
							<NavItem>
								<NavLink id="logoutButton" onClick={this.onItemClick} to="/member/logout" className="btn btn-danger btn-lg">Logout</NavLink>
							</NavItem>
						</NavbarNav>
					</Collapse>
				</Navbar>

				<div className="row" style={{ marginTop: '80px' }}>
					<div className="col">
						<div style={{ marginTop: "50px" }}>
							<Route exact path="/member/weddings" component={WeddingsPage} />
							<Route exact path="/member/account" component={AccountPage} />
							<Route exact path="/member/printers" component={PrintersPage} />
							<Route exact path="/member/feedback" component={FeedbackPage} />
							<Route exact path="/member/admin" component={AdminPage} />
							<Route exact path="/member/manage" component={ManagePage} />
							<Route exact path="/member/logout" component={LogoutPage} />
						</div>
					</div>
				</div>
				<footer>
					<div className="container">
						<div className="row">
							<div className="col-12 pt-3"><span className="copyright">noticeofintendedmarriage.online 2021</span>
							</div>
						</div>
					</div>
				</footer>
			</div>
		)
	}
}

class LogoutPage extends React.Component {
	componentDidMount() {
		axios.get("/member/logout")
			.then(function (response) {
				window.location.replace("/");
			})
			.catch(function (error) {
				Sentry.captureException(error)
				window.location.replace("/");
			})
	}
	render() {
		return (
			<div>
				<Row>
					<Col>
						Logging out...
          </Col>
				</Row>
			</div>
		)
	}
}

export default AppMemberPage
