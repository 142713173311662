import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import LandingPage from './components/public/LandingPage'
import AppMemberPage from './components/member/AppMemberPage'
import AppWeddingPage from './components/public/AppWeddingPage'

class App extends Component {
  render() {
    return (
      <div className="App container-fluid">
        <Switch>
          <Route exact path="/" component={AppWeddingPage} />
          <Route path="/wedding" component={AppWeddingPage} />
          <Route path="/member" component={AppMemberPage} />
          <Route path="/login" component={LandingPage} />
        </Switch>
      </div>
    )
  }
}

export default App
