// https://magic.reactjs.net/htmltojsx.htm

import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Spinner,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  toast,
  ToastContainer
}
from 'mdbreact'
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import "./Printers.css"
import SingleSelect from '../general/SingleSelect'
import * as Sentry from '@sentry/browser'

var fontList = [{
  fontName: "Aargau Bold",
  fontFamily: "Aargau_Bold"
}, {
  fontName: "Abuela",
  fontFamily: "Abuela"
}, {
  fontName: "AccoladeSerial Bold",
  fontFamily: "AccoladeSerial_Bold"
}, {
  fontName: "Apple Chancery",
  fontFamily: "Apple_Chancery"
}, {
  fontName: "Arial",
  fontFamily: "Arial"
}, {
  fontName: "Black Tuesday Italic",
  fontFamily: "Black_Tuesday_Italic"
}, {
  fontName: "Courier PS Bold",
  fontFamily: "Courier_PS_Bold"
}, {
  fontName: "Courier PS BoldItalic",
  fontFamily: "Courier_PS_BoldItalic"
}, {
  fontName: "Cuida",
  fontFamily: "Cuida"
}, {
  fontName: "Curacao Regular",
  fontFamily: "Curacao_Regular"
}, {
  fontName: "D690 Roman Regular",
  fontFamily: "D690_Roman_Regular"
}, {
  fontName: "Deco Regular",
  fontFamily: "Deco_Regular"
}, {
  fontName: "Dekka Dense JL",
  fontFamily: "Dekka_Dense_JL"
}, {
  fontName: "EdwardianScript",
  fontFamily: "EdwardianScript"
}, {
  fontName: "Enigma Scrawl 4 BRK",
  fontFamily: "Enigma_Scrawl_4_BRK"
}, {
  fontName: "MelbourneSerial Light Regular",
  fontFamily: "MelbourneSerial_Light_Regular"
}, {
  fontName: "MercedesSerial Light Regular",
  fontFamily: "MercedesSerial_Light_Regular"
}, {
  fontName: "Merida",
  fontFamily: "Merida"
}, {
  fontName: "Mf Wedding Bells",
  fontFamily: "Mf_Wedding_Bells"
}, {
  fontName: "Pneumatics Wide BRK",
  fontFamily: "Pneumatics_Wide_BRK"
}, {
  fontName: "Prestige Regular",
  fontFamily: "Prestige_Regular"
}, {
  fontName: "PriamosSerial Xbold Regular",
  fontFamily: "PriamosSerial_Xbold_Regular"
}, {
  fontName: "PriamosSerial Xlight Regular",
  fontFamily: "PriamosSerial_Xlight_Regular"
}, {
  fontName: "PrimerPrint Bold",
  fontFamily: "PrimerPrint_Bold"
}, {
  fontName: "Regra",
  fontFamily: "Regra"
}, {
  fontName: "ReportRg Regular",
  fontFamily: "ReportRg_Regular"
}, {
  fontName: "RiccioneSerial Italic",
  fontFamily: "RiccioneSerial_Italic"
}, {
  fontName: "RiccioneSerial Light Regular",
  fontFamily: "RiccioneSerial_Light Regular"
}, {
  fontName: "Roman Regular",
  fontFamily: "Roman_Regular"
}, {
  fontName: "SF Cartoonist Hand",
  fontFamily: "SF_Cartoonist_Hand"
}, {
  fontName: "SF Cartoonist Hand Bold",
  fontFamily: "SF_Cartoonist_Hand_Bold"
}, {
  fontName: "Sans Bold",
  fontFamily: "Sans_Bold"
}]

var fontOptions = []
_.map(_.range(8, 30), function(value) {
  fontOptions.push({
    value: value,
    label: value
  })
})

var newPrinterData = {
  id: 0,
  printer_name: "New Printer",
  up: 0,
  down: 0,
  left: 0,
  right: 0,
  font_name: "Arial",
  font_size: 0,
  font_colour: "#000000",
  user_id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  day_up: 0,
  day_down: 0,
  day_left: 0,
  day_right: 0,
  month_up: 0,
  month_down: 0,
  month_left: 0,
  month_right: 0,
  year_up: 0,
  year_down: 0,
  year_left: 0,
  year_right: 0,
  celebrant_up: 0,
  celebrant_down: 0,
  celebrant_left: 0,
  celebrant_right: 0,
  location_up: 0,
  location_down: 0,
  location_left: 0,
  location_right: 0,
  groom_up: 0,
  groom_down: 0,
  groom_left: 0,
  groom_right: 0,
  bride_up: 0,
  bride_down: 0,
  bride_left: 0,
  bride_right: 0,
  party1_sig_up: 0,
  party1_sig_down: 0,
  party1_sig_left: 0,
  party1_sig_right: 0,
  party2_sig_up: 0,
  party2_sig_down: 0,
  party2_sig_left: 0,
  party2_sig_right: 0,
}

class PrintersPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      id: 0,
      modal: false,
      printer: {},
      status: "Initial",
      mode: "E" //"E"dit printer or "N"ew printer
    }
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.newPrinter = this.newPrinter.bind(this)
    this.initialSetup = this.initialSetup.bind(this)
    this.getStatus = this.getStatus.bind(this)
  }
  componentDidMount() {
    this.getStatus()
    this.initialSetup()
  }
  initialSetup() {
    var _this = this
    axios.get("/member/api/printers/get-printers")
      .then(function(response) {
        _this.setState({
          loaded: true,
          data: response.data,
          id: 0
        })
      })
      .catch(function(error) {
        Sentry.captureException(error)
        console.log(error)
        window.location.replace("/")
      })
  }
  getStatus() {
    var _this = this
    axios.get("/member/api/users/get-status")
      .then(function(response) {
        _this.setState({
          status: response.data[0].status
        })
      })
      .catch(function(error) {
        Sentry.captureException(error)
        console.log(error)
        window.location.replace("/");
      })
  }
  open(rowInfo) {
    this.setState({
      id: rowInfo._original.id,
      modal: true,
      mode: "E"
    })
  }
  close() {
    this.setState({
      id: 0,
      modal: false
    })
    this.initialSetup()
  }
  newPrinter() {
    if (this.state.status !== "I") {
      this.setState({
        mode: "N",
        id: 0,
        modal: true
      })
    }
  }
  render() {
    var _this = this
    return (
      <div className="text-center" style={{paddingTop: "30px"}}>
        <h1>
          Printers
        </h1>
        {this.state.status === "I" ?
          <div id="inactiveAlert" className="alert alert-danger text-center mb-2 pb-0 hide" role="alert">
              <p>Your account is 'Inactive'</p>
              <p></p>
              <p>You are no longer able to create or edit weddings.</p>
              <p></p>
              <p>Please obtain a licence by clicking on "User Settings" above, and making a payment.</p>
          </div>
        :
          <div></div>
        }
        <Row>
          <Col className="text-center">
            <Button
              onClick={this.newPrinter}
              className="mt-3 mb-4 btn-lg"
              color="primary">Create Printer</Button>
          </Col>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          draggable={false}
          pauseOnHover={false}>
        </ToastContainer>
        <Row>
          {this.state.loaded
            ?
          <div id="printersTable" className="col-12">
            <ReactTable
              data={_this.state.data}
              filterable
              defaultPageSize={5}
              className="-striped -highlight"
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (rowInfo && rowInfo.row) {
                      _this.open(rowInfo.row)
                    }
                  }
                }
              }}
              getTrProps={(state, rowInfo, column) => {
                  var returnData = {
                    backgroundColor: ""
                  }
                  if (rowInfo && rowInfo.row && rowInfo.row.id === _this.state.id) {
                    returnData = {
                      // background: "#dc3545"
                      backgroundColor: "#0b51c5"
                    }
                  }
                  return {
                    style: returnData
                  }
                }}
              columns={[
                {
                  columns: [
                    {
                      Header: "Printer Name",
                      id: "id",
                      accessor: "id",
                      show: false,
                      filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["id"] }),
                      filterAll: true
                    },
                    {
                      Header: "Printer Name",
                      id: "printer_name",
                      accessor: "printer_name",
                      filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["printer_name"] }),
                      filterAll: true
                    },
                    {
                      Header: "Font Name",
                      id: "font_name",
                      accessor: "font_name",
                      filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["font_name"] }),
                      filterAll: true
                    },
                    {
                      Header: "Font Size",
                      id: "font_size",
                      accessor: "font_size",
                      filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["font_size"] }),
                      filterAll: true
                    },
                  ]
                }
              ]}
            />
          </div>
            :
            <Col className="col-12">
              <Row>
                <Col className="col-12 text-center">
                  <Spinner blue big />
                </Col>
              </Row>
            </Col>
          }
        </Row>
        <Modal style={{minWidth: "95vw", maxWidth: "95vw"}} size="fluid" isOpen={this.state.modal} toggle={this.close} keyboard={false} backdrop="static" position="top" >
          <ModalHeader>
            Maintain Printer
          </ModalHeader>
          <PrinterData id={this.state.id} close={this.close} mode={this.state.mode} status={this.state.status}/>
        </Modal>
      </div>
    )
  }
}

class PrinterData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      saveButtonText: (this.props.mode === "E", "Save Changes", "Create Printer"),
      dirty: false,
      modal: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputChangeNum = this.handleInputChangeNum.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleSizeChange = this.handleSizeChange.bind(this)
    this.closeClicked = this.closeClicked.bind(this)
    this.checkDataForChanges = this.checkDataForChanges.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.closeAll = this.closeAll.bind(this)
    this.saveClicked = this.saveClicked.bind(this)
  }
  componentDidMount() {
    var _this = this
    if (this.props.mode === "E") {
      axios.get("/member/api/printers/get-printer/" + this.props.id)
        .then(function(response) {
          var dataCopy1 = JSON.parse(JSON.stringify(response.data[0]))
          var dataCopy2 = JSON.parse(JSON.stringify(response.data[0]))
          _this.setState({
            data: dataCopy1,
            originalData: dataCopy2,
            loaded: true,
            saveButtonText: "Save Changes",
          })
        })
        .catch(function(error) {
          Sentry.captureException(error)
          console.log(error)
          // window.location.replace("/")
        })
    }
    else {
      var dataCopy1 = JSON.parse(JSON.stringify(newPrinterData))
      var dataCopy2 = JSON.parse(JSON.stringify(newPrinterData))
      _this.setState({
        loaded: true,
        data: dataCopy1,
        originalData: dataCopy2,
        saveButtonText: "Create Printer",
      })
    }
  }
  saveClicked() {
    var _this = this
    if (this.props.mode === "E") {
      axios.post("/member/api/printers/update-printer", this.state.data)
        .then(function(response) {
          if (response.status === 200) {
            _this.setState({
              originalData: JSON.parse(JSON.stringify(_this.state.data)),
              dirty: false
            })
            toast.success("Printer Information Saved!", {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
              hideProgressBar: false,
              draggable: false,
              pauseOnHover: false
            });
          }
          else {
            toast.error("ERROR! Please try again.", {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
              hideProgressBar: false,
              draggable: false,
              pauseOnHover: false
            });
          }
        })
        .catch(function(error) {
          Sentry.captureException(error)
          console.log(error)
          toast.error("ERROR! Please try again.", {
            position: "top-right",
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: false,
            draggable: false,
            pauseOnHover: false
          });
        })
    }
    else {
      axios.post("/member/api/printers/create-printer", _this.state.data)
        .then(function(response) {
          if (response.status === 200) {
            toast.success("Printer Created!", {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
              hideProgressBar: false,
              draggable: false,
              pauseOnHover: false
            });
            _this.props.close()
          }
          else {
            toast.error("ERROR! Please try again.", {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
              hideProgressBar: false,
              draggable: false,
              pauseOnHover: false
            });
          }
        })
        .catch(function(error) {
          Sentry.captureException(error)
          console.log(error)
          window.location.replace("/");
        })
    }
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    })
  }
  closeAll() {
    this.setState({
      modal: false
    }, this.props.close())
  }
  closeClicked() {
    var _this = this
    if (this.state.dirty) {
      _this.setState({
        modal: true
      })
    }
    else {
      this.props.close()
    }
  }
  checkDataForChanges() {
    var dirty = JSON.stringify(this.state.data) !== JSON.stringify(this.state.originalData)
    if (dirty !== this.state.dirty && this.props.status !== "I") {
      this.setState({
        dirty: dirty
      })
    }
  }
  handleInputChange(event) {
    var dataName = event.target.name
    var dataValue = event.target.value
    console.log(typeof dataValue)
    var newData = this.state.data
    newData[dataName] = dataValue
    this.setState({
      data: newData
    }, this.checkDataForChanges())
  }
  handleInputChangeNum(event) {
    var dataName = event.target.name
    var dataValue = 0
    if (event.target.value.length > 0) {
      dataValue = parseInt(event.target.value, 10)
    }
    var newData = this.state.data
    newData[dataName] = dataValue
    this.setState({
      data: newData
    }, this.checkDataForChanges())
  }
  handleOptionChange(item, event) {
    var newData = this.state.data
    newData.font_name = item
    this.setState({
      data: newData
    }, this.checkDataForChanges());
  }
  handleSizeChange(item, event) {
    var newData = this.state.data
    newData.font_size = event.value
    this.setState({
      data: newData
    }, this.checkDataForChanges());
  }
  render() {
    var fontHTML = [];
    if (this.state.loaded) {
      for (var x = 0; x < fontList.length; x++) {
        var buttonClass = "btn btn-sm my-0 py-2 btn-block"
        if (fontList[x].fontFamily === this.state.data.font_name) {
          buttonClass = buttonClass + " btn-success"
        }
        else {
          buttonClass = buttonClass + " btn-primary"
        }
        var thisFont = fontList[x].fontFamily
        var newItem =
          <tr key={fontList[x].fontFamily}>
            <td>
              <div>
                <button
                  onClick={this.handleOptionChange.bind(this, thisFont)}
                  className={buttonClass}>
                  {fontList[x].fontFamily}
                </button>
              </div>
            </td>
            <td>
              <div style={{fontFamily: fontList[x].fontFamily, marginLeft: "20px"}}>
                Grumpy wizards make toxic brew for the evil queen and jack.
              </div>
            </td>
          </tr>
        fontHTML.push(newItem)
      }
    }
    return (
      <div id="printerData">
          <ModalBody >
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  {this.state.loaded ?
                    <Row className="mt-3">
                      <Col>
                        <Row>
                          <Col>
                            <label>Printer Name</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <input
                              name="printer_name"
                              type="text"
                              className="form-control form-control-lg"
                              value={this.state.data.printer_name}
                              onChange={this.handleInputChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>Printer Size</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <SingleSelect
                              name="font_size"
                              defaultValue={this.state.data.font_size}
                              onChange={this.handleSizeChange}
                              options={fontOptions} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>Font Colour</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <input
                              name="font_colour"
                              type="color"
                              className="form-control form-control-lg"
                              value={this.state.data.font_colour}
                              onChange={this.handleInputChange}
                              style={{height: "50px"}}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>Font Name</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div>
                                <table>
                                    <tbody id="printerTableBody">
                                      {fontHTML}
                                    </tbody>
                                </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="mb-0 pb-0">Text Locations</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <table>
                                <tbody>
                                <tr>
                                  <td style={{width: "20%"}}>
                                    <label className="black"></label>
                                  </td>
                                  <td className="text-center">
                                    <label className="black">Up</label>
                                  </td>
                                  <td className="text-center">
                                    <label className="black">Down</label>
                                  </td>
                                  <td className="text-center">
                                    <label className="black">Left</label>
                                  </td>
                                  <td className="text-center">
                                    <label className="black">Right</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Celebrant Name</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="celebrant_up" value={this.state.data.celebrant_up} onChange={this.handleInputChangeNum} id="celebrant_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="celebrant_down" value={this.state.data.celebrant_down} onChange={this.handleInputChangeNum} id="celebrant_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="celebrant_left" value={this.state.data.celebrant_left} onChange={this.handleInputChangeNum} id="celebrant_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="celebrant_right" value={this.state.data.celebrant_right} onChange={this.handleInputChangeNum} id="celebrant_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Marriage Location</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="location_up" value={this.state.data.location_up} onChange={this.handleInputChangeNum} id="location_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="location_down" value={this.state.data.location_down} onChange={this.handleInputChangeNum} id="location_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="location_left" value={this.state.data.location_left} onChange={this.handleInputChangeNum} id="location_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="location_right" value={this.state.data.location_right} onChange={this.handleInputChangeNum} id="location_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Groom Name</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="groom_up" value={this.state.data.groom_up} onChange={this.handleInputChangeNum} id="groom_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="groom_down" value={this.state.data.groom_down} onChange={this.handleInputChangeNum} id="groom_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="groom_left" value={this.state.data.groom_left} onChange={this.handleInputChangeNum} id="groom_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="groom_right" value={this.state.data.groom_right} onChange={this.handleInputChangeNum} id="groom_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Bride Name</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="bride_up" value={this.state.data.bride_up} onChange={this.handleInputChangeNum} id="bride_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="bride_down" value={this.state.data.bride_down} onChange={this.handleInputChangeNum} id="bride_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="bride_left" value={this.state.data.bride_left} onChange={this.handleInputChangeNum} id="bride_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="bride_right" value={this.state.data.bride_right} onChange={this.handleInputChangeNum} id="bride_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Marriage Day</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="day_up" value={this.state.data.day_up} onChange={this.handleInputChangeNum} id="day_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="day_down" value={this.state.data.day_down} onChange={this.handleInputChangeNum} id="day_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="day_left" value={this.state.data.day_left} onChange={this.handleInputChangeNum} id="day_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="day_right" value={this.state.data.day_right} onChange={this.handleInputChangeNum} id="day_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Marriage Month</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="month_up" value={this.state.data.month_up} onChange={this.handleInputChangeNum} id="month_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="month_down" value={this.state.data.month_down} onChange={this.handleInputChangeNum} id="month_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="month_left" value={this.state.data.month_left} onChange={this.handleInputChangeNum} id="month_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="month_right" value={this.state.data.month_right} onChange={this.handleInputChangeNum} id="month_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Marriage Year</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="year_up" value={this.state.data.year_up} onChange={this.handleInputChangeNum} id="year_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="year_down" value={this.state.data.year_down} onChange={this.handleInputChangeNum} id="year_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="year_left" value={this.state.data.year_left} onChange={this.handleInputChangeNum} id="year_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="year_right" value={this.state.data.year_right} onChange={this.handleInputChangeNum} id="year_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Party 1 Signature</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party1_sig_up" value={this.state.data.party1_sig_up} onChange={this.handleInputChangeNum} id="party1_sig_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party1_sig_down" value={this.state.data.party1_sig_down} onChange={this.handleInputChangeNum} id="party1_sig_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party1_sig_left" value={this.state.data.party1_sig_left} onChange={this.handleInputChangeNum} id="party1_sig_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party1_sig_right" value={this.state.data.party1_sig_right} onChange={this.handleInputChangeNum} id="party1_sig_right" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="black">Party 2 Signature</label>
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party2_sig_up" value={this.state.data.party2_sig_up} onChange={this.handleInputChangeNum} id="party2_sig_up" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party2_sig_down" value={this.state.data.party2_sig_down} onChange={this.handleInputChangeNum} id="party2_sig_down" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party2_sig_left" value={this.state.data.party2_sig_left} onChange={this.handleInputChangeNum} id="party2_sig_left" />
                                  </td>
                                  <td>
                                  	<input className="form-control" type="text" name="party2_sig_right" value={this.state.data.party2_sig_right} onChange={this.handleInputChangeNum} id="party2_sig_right" />
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  :
                    <Col className="col-12">
                      <Row>
                        <Col className="col-12 text-center">
                          <Spinner blue big />
                        </Col>
                      </Row>
                    </Col>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button disabled={!this.state.dirty} className="btn btn-lg" color="success" onClick={this.saveClicked}>{this.state.saveButtonText}</Button>
          <Button className="btn btn-lg" color="warning" onClick={this.closeClicked}>Close</Button>
        </ModalFooter>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>Unsaved Printer Changes</ModalHeader>
          <ModalBody>
            <p>You have made changes to this printer that have not been saved</p>
            <p>Would you like to continue changing the printer or delete the changes and close the printer?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.toggleModal}>Continue</Button>
            <Button color="danger" onClick={this.closeAll}>Delete Changes</Button>
          </ModalFooter>
        </Modal>
    </div>
    )
  }
}

export default PrintersPage
