// https://magic.reactjs.net/htmltojsx.htm

import React, { Component } from 'react'
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  toast,
  ToastContainer,
  Spinner
}
from 'mdbreact'
import ReactQuill from 'react-quill'
import * as Sentry from '@sentry/browser'
import 'react-quill/dist/quill.snow.css'


// import $ from 'jquery'
// import 'bootstrap'

class AdminPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      topMessage: "Loading...",
      bottomMessage: "Loading...",
      subjectMessage: "Loading...",
      emailMessage: "Loading...",
      emails: "Loading..."
    }
    this.submitMessages = this.submitMessages.bind(this)
    this.normalChange = this.normalChange.bind(this)
		this.onChange = {
			topMessage: this.onChange.bind(this, 'topMessage'),
			bottomMessage: this.onChange.bind(this, 'bottomMessage'),
			emailMessage: this.onChange.bind(this, 'emailMessage')
		}
  }
  componentDidMount() {
    var _this = this
    axios.get('/public/api/get-admin')
    .then(function(response) {
      _this.setState({
        loaded: true,
        topMessage: response.data[0].topMessage,
        bottomMessage: response.data[0].bottomMessage,
        emailMessage: response.data[0].emailMessage,
        subjectMessage: response.data[0].subjectMessage,
        emails: response.data[0].emails
      })
    })
    .catch(function(error) {
      Sentry.captureException(error)
      console.log(error)
    })
  }
	normalChange(event) {
	  this.setState({
	    [event.target.name]: event.target.value,
	    disabled: false
	  })
	}
	onChange(name, event) {
	  this.setState({
	    [name]: event,
	    disabled: false
	  })
	}

  submitMessages() {
    var _this = this
    axios.post("/member/api/update-admin", this.state)
      .then(function(response) {
        toast.success("Save Successful!", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: false,
          draggable: false,
          pauseOnHover: false
        });
        _this.setState({
          disabled: true
        })
      })
      .catch(function(error) {
        Sentry.captureException(error)
        console.log(error)
        toast.error("Error! Unable to update!", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: false,
          draggable: false,
          pauseOnHover: false
        });
        // window.location.replace("/");
      })
  }
  render() {
		var modules = {
		    toolbar: [
		      [{ 'header': [1, 2, 3, 4, 5, false] }],
		      // ['bold', 'italic', 'underline','strike', 'blockquote'],
		      ['bold', 'italic', 'underline','strike'],
		      // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
		      [{'color': ['black','red', 'green', 'blue', 'orange', 'violet', 'purple', 'pink']}],
          // [{'align': []}],
          ['link', 'image'],
		      ['clean'],
		    ]
		  }
		  var formats = [
		    'header',
		    'bold', 'italic', 'underline', 'strike',// 'blockquote',
		    // 'list', 'bullet', 'color'
        'color',
        'link', 'image'
		  ]
		var modulesNoImage = {
		    toolbar: [
		      [{ 'header': [1, 2, 3, 4, 5, false] }],
		      // ['bold', 'italic', 'underline','strike', 'blockquote'],
		      ['bold', 'italic', 'underline','strike'],
		      // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
		      [{'color': ['black','red', 'green', 'blue', 'orange', 'violet', 'purple', 'pink']}],
          // [{'align': []}],
          ['link'],
		      ['clean'],
		    ]
		  }
		  var formatsNoImage = [
		    'header',
		    'bold', 'italic', 'underline', 'strike',// 'blockquote',
		    // 'list', 'bullet', 'color'
        'color',
        'link'
		  ]

    return (
      <div className="text-center" style={{paddingTop: "30px"}}>
        <h1>
          Admin
        </h1>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          draggable={false}
          pauseOnHover={false}>
        </ToastContainer>
        <Row>
          <Col className="col-1"></Col>
          <Col className="col-10 mt-4">
            <Card className="h-100">
              {this.state.loaded ?
              <CardBody>
                <Row>
                  <Col className="text-center">
                    <h3>Top NOIM Text</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      defaultValue={this.state.topMessage}
                      onChange={this.onChange.topMessage}
                      ref={(el) => { this.reactQuillRef = el }}
                      />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-center">
                    <h3>Bottom NOIM Text</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      defaultValue={this.state.bottomMessage}
                      onChange={this.onChange.bottomMessage}
                      ref={(el) => { this.reactQuillRef = el }}
                      />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-center">
                    <h3>Public Email Subject</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input
                      name="subjectMessage"
                      className="form-control form-control-lg"
                      value={this.state.subjectMessage}
                      onChange={this.normalChange}
                      />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-center">
                    <h3>Public Email Text</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReactQuill
                      theme="snow"
                      modules={modulesNoImage}
                      formats={formatsNoImage}
                      defaultValue={this.state.emailMessage}
                      onChange={this.onChange.emailMessage}
                      ref={(el) => { this.reactQuillRef = el }}
                      />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-center">
                    <h3>Public Email Notification List</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>(Please have 1 email address per line, with no commas)</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <textarea
                      style={{width: "100%", fontSize: "18px"}}
                      name="emails"
                      type="textarea"
                      rows="5"
                      value={this.state.emails}
                      onChange={this.normalChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button
                      disabled={this.state.disabled}
                      onClick={this.submitMessages}
                      className="btn btn-success mt-3 btn-lg btn-block">Submit</button>
                  </Col>
                </Row>
              </CardBody>
              :
                <CardBody>
                  <Row>
                    <Col className="col-12 text-center">
                      <Spinner blue big />
                    </Col>
                  </Row>
                </CardBody>
                }
            </Card>
          </Col>
          <Col className="col-1"></Col>
        </Row>
      </div>
    )
  }
}

export default AdminPage
