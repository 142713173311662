import React from 'react'
import { Container } from 'mdbreact'
import { Redirect } from 'react-router-dom'
import { ModalFooter, Modal, ModalBody, Mask, Fa, View } from 'mdbreact'
import "./LandingPage.css"
import axios from "axios"
import * as Sentry from '@sentry/browser'

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
        password: "",
        password2: "",
        forgetEmail: "",
      },
      invalidData: false,
      uniqueAlert: false,
      signupAlert: false,
      loginButtonText: "LOG IN",
      collapse: false,
      isWideEnough: false,
      formActivePanel: 1,
      activeItem: '1',
      modal: true,
      modalPassword: false,
      redirect: false,
      invalidPasswordClass: "",
      showEmailReset: false
    }
    this.onClick = this.onClick.bind(this)
    this.swapFormActive = this.swapFormActive.bind(this)
    this.attemptLogin = this.attemptLogin.bind(this)
    this.toggle = this.toggle.bind(this)
    this.renderRedirect = this.renderRedirect.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
  }
  renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to='/member/weddings' />
    }
  }
  resetPassword() {
    var _this = this
    if (_this.state.data.forgetEmail.length > 0) {
      axios.post('/public/api/reset-password', { email: _this.state.data.forgetEmail })
        .then(function(response) {
          console.log(response)
          _this.setState({
            showEmailReset: true
          })
        })
        .catch(function(error) {
          Sentry.captureException(error)
          console.log(error)
        })
    }
  }
  attemptLogin() {
    var _this = this
    if (this.state.data.email !== "" && this.state.data.password !== "") {
      _this.setState({
        loginButtonText: "LOGGING IN..."
      })
      var postData = {
        email: _this.state.data.email,
        password: _this.state.data.password
      }
      axios.post("/public/api/login", postData)
        .then(function(response) {
          _this.setState({
            invalidData: false,
            redirect: true
          })
        })
        .catch(function(error) {
          Sentry.captureException(error)
          _this.setState({
            invalidData: true,
            loginButtonText: "LOG IN"
          })
        })
    }
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    })
  }
  swapFormActive = (param) => (e) => {
    this.setState({
      formActivePanel: param
    });
  }
  handleInputChange(event) {
    var newData = this.state.data
    newData[event.target.name] = event.target.value
    var newInvalidClass;
    if (newData.password !== newData.password2) {
      newInvalidClass = " is-invalid"
    }
    else {
      newInvalidClass = ""
    }
    this.setState({
      data: newData,
      invalidPasswordClass: newInvalidClass
    })
  }
  render() {
    return (
      <div id="page-top" style={{backgroundColor: "black"}}>
        {this.renderRedirect()}
      <Modal className="form-cascading" isOpen={this.state.modal} toggle={this.toggle} centered>
        <ModalBody className="mt-2">
          <h1 className="text-center"><strong>LOGIN</strong></h1>
          <form className="grey-text">
            {this.state.invalidData ?
              <div id="invalidAlert" className="alert alert-danger text-center mt-0 mb-2" role="alert">
                Invalid Email Address or Password
              </div>
            :
            <div></div>
            }
            <label><i className="fa fa-envelope prefix"></i> Your email</label>
            <input
              id="emailField"
              name="email"
              type="text"
              className="form-control form-control-lg"
              value={this.state.data.email}
              onChange={this.handleInputChange}
            />
            <label className="mt-3"><i className="fa fa-lock prefix"></i> Your password</label>
            <input
              id="passwordField"
              name="password"
              type="password"
              className="form-control form-control-lg"
              value={this.state.data.password}
              onChange={this.handleInputChange}
            />
          </form>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button id="loginFormButton" className="btn btn-lg btn-success mb-2" onClick={this.attemptLogin}>{this.state.loginButtonText} <Fa icon="sign-in" className="ml-1" /></button>
        </ModalFooter>
      </Modal>
        <div id="header-area">
          <View>
            <Mask className="d-flex justify-content-center align-items-center">
              <Container>
              </Container>
            </Mask>
          </View>
        </div>
    </div>
    );
  }
}

export default LandingPage;
