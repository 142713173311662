// https://magic.reactjs.net/htmltojsx.htm

import React, { Component } from 'react'
import axios from 'axios'
import {
	Spinner,
	Row,
	Col,
	toast,
	ToastContainer
}
	from 'mdbreact'
import matchSorter from 'match-sorter'
import ReactTable from "react-table"
import * as Sentry from '@sentry/browser'
import "./Manage.css"

class ManagePage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loaded: false,
			id: 0,
			total: "",
			available: ""
		}
		this.dataSetup = this.dataSetup.bind(this)
	}
	componentDidMount() {
		this.dataSetup()
	}
	dataSetup() {
		var _this = this
		axios.get("/member/api/users/get-users")
			.then(function (response) {
				_this.setState({
					loaded: true,
					data: response.data
				})
			})
			.catch(function (error) {
				Sentry.captureException(error)
				// window.location.replace("/");
			})
		axios.get("/member/api/misc-data")
			.then(function (response) {
				_this.setState({
					total: response.data.total,
					available: response.data.available
				})
			})
			.catch(function (error) {
				Sentry.captureException(error)
				window.location.replace("/");
			})
	}
	activateButtonClicked(item, event) {
		var _this = this
		axios.get("/member/api/users/" + item + "/activate/afcc")
			.then(function (response) {
				toast.success("User Activated!", {
					position: "top-right",
					autoClose: 3000,
					closeButton: false,
					hideProgressBar: false,
					draggable: false,
					pauseOnHover: false
				});
				_this.dataSetup()
			})
			.catch(function (error) {
				Sentry.captureException(error)
				toast.error("ERROR! Please try again.", {
					position: "top-right",
					autoClose: 3000,
					closeButton: false,
					hideProgressBar: false,
					draggable: false,
					pauseOnHover: false
				});
			})
	}
	render() {
		var _this = this
		return (
			<div className="text-center" style={{ paddingTop: "30px" }}>
				<h1>
					Manage Users
        </h1>
				<Row>
					<Col>
						<p>Available Licences: {this.state.available}</p>
						<p>Total Licences: {this.state.total}</p>
						<p></p>
					</Col>
				</Row>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					closeButton={false}
					hideProgressBar
					newestOnTop={false}
					rtl={false}
					draggable={false}
					pauseOnHover={false}>
				</ToastContainer>
				<Row>
					{this.state.loaded
						?
						<div id="usersTable" className="col-12">
							<ReactTable
								data={_this.state.data}
								filterable
								defaultPageSize={10}
								className="-striped -highlight"
								columns={[
									{
										columns: [
											{
												Header: "ID",
												id: "id",
												accessor: "id",
												show: false,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ["id"] }),
												filterAll: true
											},
											{
												Header: "Name",
												id: "full_name",
												accessor: "full_name",
												show: true,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ["full_name"] }),
												filterAll: true
											},
											{
												Header: "Email",
												id: "email",
												accessor: "email",
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ["email"] }),
												filterAll: true
											},
											{
												Header: "Status",
												id: "status",
												accessor: "status",
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ["status"] }),
												filterAll: true
											},
											{
												Header: "Last Login",
												id: "last_login_date",
												accessor: "last_login_date",
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ["last_login_date"] }),
												filterAll: true
											},
											{
												Header: "Actions",
												Cell: row => (
													<div
														style={{
															width: '100%',
															height: '100%',
															backgroundColor: '#dadada',
															borderRadius: '2px'
														}}
													>
														{(row.row.status !== "Active" && this.state.available > 0 ?
															<button
																onClick={this.activateButtonClicked.bind(this, row.row.id)}
																className="btn btn-sm btn-block btn-primary">Activate</button>
															:
															<div></div>
														)}
													</div>
												)
											}
										]
									}
								]}
							/>
						</div> :
						<Col className="col-12">
							<Row>
								<Col className="col-12 text-center">
									<Spinner blue big />
								</Col>
							</Row>
						</Col>
					} </Row> </div>
		)
	}
}

export default ManagePage
